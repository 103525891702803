import type { EncodeObject } from "@cosmjs/proto-signing";

import type { Balance } from "shared/api/balances";
import type { StakingParamsResponse } from "shared/api/staking";

import type { useAdvancedForm } from "./ui/AdvancedForm";

type GetTxMessageValueProps = {
  amountValue: string;
  params: StakingParamsResponse;
  proposalId?: string;
  senderValue: string;
  type: "delegate" | "vote";
  validator: string;
  voteValue: string;
};

const tsError = (type: never) => type;

export const getTxMessageValue = ({
  amountValue,
  params,
  proposalId,
  senderValue,
  type,
  validator,
  voteValue,
}: GetTxMessageValueProps) => {
  if (type === "delegate") {
    const message: EncodeObject = {
      typeUrl: "/cosmos.staking.v1beta1.MsgDelegate",
      value: {
        amount: {
          amount: amountValue,
          denom: params.params.bond_denom,
        },
        delegatorAddress: senderValue,
        validatorAddress: validator,
      },
    };
    return message;
  }

  if (type === "vote") {
    const message: EncodeObject = {
      typeUrl: "/cosmos.gov.v1beta1.MsgVote",
      value: {
        option: Number(voteValue),
        proposalId: proposalId,
        voter: senderValue,
      },
    };
    return message;
  }

  tsError(type);
  throw new Error(`Invalid transaction type: ${type}`);
};

type ValidationFormProps = {
  advancedForm: ReturnType<typeof useAdvancedForm>;
  amountValue: string;
  balance?: Balance;
  proposalId?: string;
  senderValue: string;
  type: "delegate" | "vote";
  validator: string;
  voteValue: string;
};

export const validationForm = ({
  advancedForm,
  amountValue,
  balance,
  proposalId,
  senderValue,
  type,
  validator,
  voteValue,
}: ValidationFormProps) => {
  let isValid = true;
  let errorMessage = "An unknown error occurred";

  if (!advancedForm.feesValue) {
    errorMessage = "Fee Denom should not be empty!";
    isValid = false;
  }
  if (!senderValue) {
    errorMessage = "Sender should not be empty!";
    isValid = false;
  }
  if (!balance) {
    errorMessage = "Balance is empty!";
    isValid = false;
  }

  if (type === "delegate") {
    if (!validator) {
      errorMessage = "Validator should not be empty!";
      isValid = false;
    }
    if (!amountValue) {
      errorMessage = "Amount should not be empty!";
      isValid = false;
    }
  }

  if (type === "vote") {
    if (!proposalId) {
      errorMessage = "No found proposal item!";
      isValid = false;
    }
    if (!voteValue) {
      errorMessage = "Option should not be empty!";
      isValid = false;
    }
  }

  return { errorMessage, isValid };
};
