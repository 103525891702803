import type { ComponentPropsWithoutRef } from "react";
import { memo } from "react";

import { twMerge } from "tailwind-merge";

import type { TableProps, TableRowExt } from "../types";

import { TdSmall } from "./small-td";

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  hasTh?: boolean;
  hovered?: boolean;
  row: Row;
} & Pick<TableProps<ColumnKey, Row>, "columns" | "onRowClick">;

export const TrTdSmall = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  columns,
  hasTh,
  hovered,
  onRowClick,
  row,
}: Props<ColumnKey, Row>) => {
  return (
    <TrUISmall className={onRowClick && "cursor-pointer"} onClick={() => onRowClick?.(row)}>
      {columns.map((column) => (
        <TdSmall
          column={column}
          hasTh={hasTh}
          hovered={hovered}
          isClicked={!!onRowClick}
          key={column.key}
          row={row}
        />
      ))}
    </TrUISmall>
  );
};

export const TrUISmall = (props: ComponentPropsWithoutRef<"tr">) => {
  return <tr {...props} className={twMerge("group", props.className)} />;
};

export const SmallTrTdMemo = memo(TrTdSmall) as typeof TrTdSmall;
