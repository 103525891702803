import { useCallback, useId, useState } from "react";

import { DateTime } from "luxon";
import { Area, Bar, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { theme } from "app/theme";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getFormattedNumberWithUnit } from "shared/helpers/getFormattedNumberWithUnit";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";

import { Icon } from "../Icon";

type Props = {
  data: {
    date: string;
    total: number;
  }[];
  isEmptyData?: boolean;
};

export const LineChart = ({ data, isEmptyData }: Props) => {
  const [yAxisWidth, setYAxisWidth] = useState(20);

  const xl2 = useMinWidthMediaQuery("2xl");

  const axisFontSize = xl2 ? 10 : 8;

  const gradId = useId();

  const tickFormatter = useCallback(
    (balance: number) => {
      const label = `${balance}`;
      const width = label.length * axisFontSize;
      setTimeout(() => setYAxisWidth((prev) => (prev > width ? prev : width)));

      return getFormattedNumber(label);
    },
    [axisFontSize],
  );

  return (
    <ResponsiveContainer>
      <ComposedChart
        className="bg-black"
        data={data}
        margin={{ bottom: 0, left: 0, right: 24, top: 24 }}
      >
        <YAxis
          allowDataOverflow={false}
          allowDecimals={false}
          axisLine={false}
          domain={isEmptyData ? [0, 4] : [0, (dataMax: number) => dataMax * 5]}
          // domain={isEmptyData ? [0, 1] : undefined}
          hide={true}
          minTickGap={0}
          tick={{
            color: theme.colors.corduroy[700],
            fontFamily: "Inter",
            fontSize: axisFontSize,
            fontWeight: 600,
          }}
          tickFormatter={tickFormatter}
          tickLine={false}
          width={yAxisWidth}
          yAxisId="left"
        />

        <YAxis
          domain={[(dataMin: number) => dataMin + 10, (dataMax: number) => dataMax + 10]}
          hide={true}
          orientation="right"
          padding={{ bottom: 40 }}
          yAxisId="right"
        />

        <XAxis
          allowDataOverflow={false}
          axisLine={false}
          dataKey="date"
          hide={true}
          interval={1}
          minTickGap={0}
          tick={({ payload, x, y }: AxisTickProps) => {
            const date = DateTime.fromISO(payload.value);

            return (
              <g transform={`translate(${x},${y})`}>
                <text
                  dy={12}
                  fill={theme.colors.corduroy[700]}
                  fontFamily="Inter"
                  fontSize={axisFontSize}
                  fontWeight={600}
                  textAnchor="middle"
                  x={0}
                  y={0}
                >
                  {date.toLocaleString(DateTime.DATE_SHORT)}
                </text>
              </g>
            );
          }}
          tickLine={false}
        />

        <defs>
          <linearGradient gradientUnits="objectBoundingBox" id={gradId} x1="1" x2="1" y1="0" y2="1">
            <stop stopColor="#03AD9D" />
            <stop offset="1" stopColor="#03AD9D" stopOpacity="0" />
          </linearGradient>
        </defs>

        <Bar barSize={5} dataKey="total" fill={theme.colors.clay[380]} yAxisId="left" />

        <Area
          activeDot={{
            fill: theme.colors.white,
            height: 8,
            stroke: theme.colors.tusk[100],
            strokeWidth: 2,
            width: 8,
          }}
          dataKey="total"
          fill={`url(#${gradId})`}
          stroke={theme.colors.tusk[100]}
          strokeWidth={3}
          type="linear"
          yAxisId="right"
        />

        <Tooltip
          content={({ active, payload }) => {
            const data: Props["data"][number] = payload?.[0]?.payload;
            if (!data || !active) return null;
            return (
              <div className="relative z-30 flex w-52 flex-col gap-3 rounded-lg border border-white/10 bg-white/5 p-3">
                <div className="absolute inset-0 -z-10 rounded-lg backdrop-blur-xl"></div>

                <div className="text-xs font-light text-white">
                  {`${DateTime.fromISO(data.date).toFormat("d LLLL yyyy")}`}
                </div>

                <div className="grid grid-cols-2 items-center justify-between gap-2">
                  <div>
                    <div className="text-xs font-light text-clay-300">Price</div>
                    <div className="mt-1 text-lg/none font-normal text-white">
                      {typeof data.total === "number"
                        ? `$${getFormattedNumberWithUnit(data.total)}`
                        : "0"}
                    </div>
                  </div>

                  <div>
                    <div className="text-xs font-light text-clay-300">Volume</div>
                    <div className="mt-1 text-lg/none font-normal text-white">
                      {typeof data.total === "number"
                        ? getFormattedNumberWithUnit(data.total)
                        : "0"}
                    </div>
                  </div>
                </div>

                <div className="flex items-center text-xs font-medium text-white">
                  <Icon
                    className="mr-2 size-6 rounded-full bg-clay-900 p-1.5 text-tusk-100"
                    name="logo"
                  />
                  NESA AI&nbsp;<span className="text-clay-300">($NES)</span>
                </div>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: "none" }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

type AxisTickProps = {
  payload: { value: string };
  x: number;
  y: number;
};
