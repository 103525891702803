import type { EncodeObject, TxBodyEncodeObject } from "@cosmjs/proto-signing";
import type { SignerData } from "@cosmjs/stargate";
import type { StdFee } from "@keplr-wallet/types";

import { wasmTypes } from "@cosmjs/cosmwasm-stargate/build/modules";
import { toBase64 } from "@cosmjs/encoding";
import { Registry, makeAuthInfoBytes } from "@cosmjs/proto-signing";
import { defaultRegistryTypes } from "@cosmjs/stargate";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { Any } from "cosmjs-types/google/protobuf/any";

import type { Writeable } from "shared/types";

export type Transaction = {
  chainId: string;
  fee: Writeable<StdFee>;
  memo: string;
  messages: readonly EncodeObject[];
  signerAddress: string;
  signerData: SignerData;
};

type Props = {
  transaction: Transaction;
};

export const getTxBytes = ({ transaction }: Props) => {
  const registry = new Registry([...defaultRegistryTypes, ...wasmTypes]);

  const pubkey = Any.fromPartial({
    typeUrl: "/cosmos.crypto.ed25519.PubKey",
    value: new Uint8Array(),
  });

  const txBodyEncodeObject: TxBodyEncodeObject = {
    typeUrl: "/cosmos.tx.v1beta1.TxBody",
    value: {
      memo: transaction.memo,
      messages: transaction.messages,
    },
  };

  const txBodyBytes = registry.encode(txBodyEncodeObject);
  const gasLimit = Number(transaction.fee.gas);

  const authInfoBytes = makeAuthInfoBytes(
    [{ pubkey, sequence: transaction.signerData.sequence }],
    transaction.fee.amount,
    gasLimit,
    transaction.fee.granter,
    transaction.fee.payer,
  );

  const txRaw = TxRaw.fromPartial({
    authInfoBytes: authInfoBytes,
    bodyBytes: txBodyBytes,
    signatures: [new Uint8Array()],
  });
  const txBytes = toBase64(TxRaw.encode(txRaw).finish());

  return txBytes;
};
