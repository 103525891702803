// /cosmos/bank/v1beta1/supply/by_denom?denom=uosmo

import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { SupplyResponse } from "./types";

import { bankKeys } from "./queryKeys";

type Params = {
  denom?: string;
  network?: ChainInfo;
};

export const useSupplyByDenomQuery = (
  { denom = "unes", network = nesaTestnet }: Params = {},
  options: UseQueryOptions<SupplyResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<SupplyResponse>(
        `${network.rest}/cosmos/bank/v1beta1/supply/by_denom`,
        { params: { denom }, signal },
      );

      return data;
    },
    queryKey: bankKeys.denom({ baseUrl: network.rest, denom }),
    ...options,
  });
};
