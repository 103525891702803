import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { Network } from "shared/helpers/getBaseEndpoints";
import type { UseQueryOptions } from "shared/types";

import { getBaseEndpoints } from "shared/helpers/getBaseEndpoints";

import type { AccountInfoResponse } from "./types";

import { authKeys } from "./queryKeys";

type Params = {
  address: string;
  network?: Network;
};

export const useAccountInfoQuery = (
  { address, network = "testnet" }: Params,
  options: UseQueryOptions<AccountInfoResponse> = {},
) => {
  const baseUrl = getBaseEndpoints(network);
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<AccountInfoResponse>(
        `${baseUrl}/cosmos/auth/v1beta1/accounts/${address}`,
        { signal },
      );

      return data;
    },
    queryKey: authKeys.account({ address, baseUrl }),
    ...options,
  });
};
