import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import type { UseQueryOptions } from "shared/types";

import { axiosNodeInstance } from "shared/utils/axiosInstance";

import { transactionKeys } from "./queryKeys";

export type TransactionQueryResponse = {
  _id: string;
  height: number;
  processed: boolean;
  tx: {
    auth_info: {
      fee: {
        amount: {
          amount: string;
          denom: string;
        }[];
        gas_limit: string;
        granter: string;
        payer: string;
      };
      signer_infos: {
        mode_info: {
          single: {
            mode: string;
          };
        };
        public_key: {
          "@type": string;
          key: string;
        };
        sequence: string;
      }[];
      tip: null;
    };
    body: {
      extension_options: [];
      memo: string;
      messages: {
        "@type": string;
        account: string;
        lock_balance: {
          amount: string;
          denom: string;
        };
        model_name: string;
        session_id: string;
        vrf: {
          hash_random: string;
          proof: string;
          seed: string;
        };
      }[];
      non_critical_extension_options: unknown[];
      timeout_height: string;
    };
    signatures: string[];
  };
  tx_response: {
    code: 0;
    codespace: string;
    data: string;
    events: {
      attributes: {
        index: boolean;
        key: string;
        value: string;
      }[];
      type: string;
    }[];
    gas_used: string;
    gas_wanted: string;
    height: string;
    info: string;
    logs: [];
    raw_log: string;
    timestamp: string;
    tx: {
      "@type": string;
      auth_info: {
        fee: {
          amount: {
            amount: string;
            denom: string;
          }[];
          gas_limit: string;
          granter: string;
          payer: string;
        };
        signer_infos: {
          mode_info: {
            single: {
              mode: string;
            };
          };
          public_key: {
            "@type": string;
            key: string;
          };
          sequence: string;
        }[];
        tip: null | unknown;
      };
      body: {
        extension_options: [];
        memo: string;
        messages: {
          "@type": string;
          account: string;
          lock_balance: {
            amount: string;
            denom: string;
          };
          model_name: string;
          session_id: string;
          vrf: {
            hash_random: string;
            proof: string;
            seed: string;
          };
        }[];
        non_critical_extension_options: [];
        timeout_height: string;
      };
      signatures: string[];
    };
    txhash: string;
  };
  txhash: string;
};

type Response = {
  limit: number;
  list: TransactionQueryResponse[];
  skip: number;
  total_count: number;
};

type Params = {
  limit: number;
  skip: number;
};

export const useLastTransactionQuery = (
  params: Params,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosNodeInstance.get<Response>("/transactions/last", {
        params,
        signal,
      });
      return data;
    },
    queryKey: transactionKeys.lastTransactions(params),
    ...options,
  });
};

export const useLastTransactionInfiniteQuery = (params: Pick<Params, "limit">) => {
  return useInfiniteQuery({
    getNextPageParam: (last: TransactionQueryResponse[], all: TransactionQueryResponse[][]) =>
      last.length === params.limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const { data } = await axiosNodeInstance.get<Response>("/transactions/last", {
        params: {
          limit: params.limit,
          skip: pageParam * params.limit,
        },
        signal,
      });
      return data.list;
    },
    queryKey: transactionKeys.lastTransactionsInfinite(params),
  });
};
