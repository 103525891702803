import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { SlashingParamResponse } from "./types";

import { slashingKeys } from "./queryKeys";

type Params = {
  network?: ChainInfo;
};

export const useGetSlashingParamsQuery = (
  { network = nesaTestnet }: Params,
  options: UseQueryOptions<SlashingParamResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<SlashingParamResponse>(
        `${network.rest}/cosmos/slashing/v1beta1/params`,
        { signal },
      );

      return data;
    },
    queryKey: slashingKeys.list({ type: "params", url: network.rest }),
    ...options,
  });
};
