import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  classNameLine?: string;
  percent: number;
};

export const ProgressLine = ({ className, classNameLine, percent }: Props) => {
  return (
    <div
      className={twMerge(
        "relative col-span-3 h-6 w-full overflow-hidden rounded bg-corduroy-200",
        className,
      )}
    >
      <div
        className={twMerge("h-full bg-primary-800", classNameLine)}
        style={{
          width: `${percent}%`,
        }}
      />
      <div className="absolute left-2 top-1/2 -translate-y-1/2 text-sm text-white">{percent}%</div>
    </div>
  );
};
