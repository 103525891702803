import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { IconName } from "shared/ui/Icon";

import { DashboardTableBlocks } from "features/DashboardTableBlocks";
import { DashboardTableTransactions } from "features/DashboardTableTransactions";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { ProposalItem } from "pages/Governance/ui/ProposalItem";
import { useSupplyByDenomQuery } from "shared/api/bank/useSupplyByDenomQuery";
import { useTotalAccountsQuery } from "shared/api/explorer-accounts/useTotalAccountsQuery";
import { useProposalsQuery } from "shared/api/proposals";
import { useStakingPoolQuery } from "shared/api/staking/useStakingPoolQuery";
import { useLastTransactionQuery } from "shared/api/transactions/useLastTransaction";
import { useTotalTransactionByPeriodQuery } from "shared/api/transactions/useTotalTransactionByPeriodQuery";
import { useTotalTransactionQuery } from "shared/api/transactions/useTotalTransactionQuery";
import { fromUnesToNes } from "shared/helpers/fromUnesToNes";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { EmptyText } from "shared/ui/EmptyText";
import { Icon } from "shared/ui/Icon";
import { LineChart } from "shared/ui/LineChart";
import { Spinner } from "shared/ui/Spinner";

import bgVideo from "./assets/bg.mp4";
import introVideo from "./assets/intro.mp4";

type StatisticsItem = {
  icon?: IconName;
  title: string;
  value: string;
};

const now = DateTime.now();

export const Dashboard = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(() => {
    const visited = sessionStorage.getItem("firstLoad");
    return !visited;
  });

  const { chainId } = useParams<{ chainId: string }>();
  const navigate = useNavigate();

  const network = getChainById(chainId || "");

  const blocks = useBlocksStore((state) => state.blocksHistory);
  const latestBlock = blocks[0];
  const isLatestLoading = !latestBlock;

  const sm = useMinWidthMediaQuery("sm");

  const { data: latestTransactions, isLoading: isLatestTransactionsLoading } =
    useLastTransactionQuery({ limit: 20, skip: 0 });

  const txs = useMemo(() => {
    const txs = blocks.flatMap(({ latestBlock, transactions }) =>
      transactions.map((tx) => ({ blockHeight: latestBlock, tx })),
    );
    if (txs.length >= 7) return txs;
    const set = new Set(txs.map(({ tx }) => tx));

    const result: {
      blockHeight: string;
      tx: TransactionQueryResponse | string;
    }[] = [...txs];

    latestTransactions?.list.forEach((tx) => {
      if (!set.has(tx.txhash)) {
        result.push({ blockHeight: tx.tx_response.height, tx });
      }
    });
    return result;
  }, [latestTransactions, blocks]);

  const { data: supply } = useSupplyByDenomQuery({ network });
  const { data: poolData, isLoading: isPoolLoading } = useStakingPoolQuery({ network });

  const { data: totalTransactions } = useTotalTransactionQuery({ refetchInterval: 5 * 1000 });

  const { data: txChart, isPending: isLoadingTxChart } = useTotalTransactionByPeriodQuery({
    endDate: now.endOf("day").toISO(),
    startDate: now.minus({ days: 30 }).startOf("day").toISO(),
  });

  const { data: totalAccounts } = useTotalAccountsQuery({
    network,
  });

  const { data: proposals, isLoading: isProposalsLoading } = useProposalsQuery({ network });

  const filtered = proposals?.proposals.filter(
    (el) => el.status === "PROPOSAL_STATUS_VOTING_PERIOD",
  );

  const statisticsItems: StatisticsItem[] = [
    { icon: "layers", title: "Block Height", value: getFormattedNumber(latestBlock?.latestBlock) },
    { icon: "scanEye", title: "Validators", value: getFormattedNumber(latestBlock?.validators) },
    {
      icon: "circleDot",
      title: "Bonded Tokens",
      value: poolData?.pool.bonded_tokens
        ? getFormattedNumber(fromUnesToNes(poolData?.pool.bonded_tokens).toNumber())
        : poolData?.pool.bonded_tokens ?? "-",
    },
    {
      icon: "database",
      title: "Supply",
      value: supply?.amount?.amount
        ? getFormattedNumber(fromUnesToNes(supply.amount.amount).toNumber())
        : "-",
    },
    {
      icon: "arrowLeftRight",
      title: "Total Transactions",
      value: totalTransactions ? getFormattedNumber(totalTransactions.total) : "-",
    },
    {
      icon: "user",
      title: "Total Accounts",
      value: totalAccounts ? getFormattedNumber(totalAccounts) : "-",
    },
  ];

  const footerItems: StatisticsItem[] = [
    { title: "nesa.ai", value: "Main Site" },
    { title: "beta.nesa.ai", value: "Playground" },
    { title: "docs.nesa.ai", value: "Docs" },
    {
      title: "nesa.ai/token",
      value: "Token",
    },
    {
      title: "nesa.ai/terms",
      value: "Terms",
    },
    {
      title: "nesa.ai/privacy",
      value: "Privacy",
    },
  ];

  return (
    <AnimateRoute className="flex w-full flex-col">
      <div
        className={twMerge("relative flex flex-col gap-10 border-b border-clay-900 pl-4 sm:pl-20")}
      >
        <AnimatePresence mode="wait">
          {isFirstLoad ? (
            <motion.div
              animate={{ opacity: 1 }}
              className="flex flex-col gap-10 pb-28 pt-14"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key="intro"
              transition={{ duration: 0.5 }}
            >
              <span className="z-10 w-fit rounded-full bg-clay-900 px-3 py-2 text-sm text-tusk-100">
                Announcement
              </span>

              <video
                autoPlay
                className="absolute left-0 top-0 size-full object-cover"
                id="intro"
                muted
                onEnded={() => {
                  setIsFirstLoad(false);
                  sessionStorage.setItem("firstLoad", "true");
                }}
                playsInline
                src={introVideo}
              ></video>

              <span className="z-10 -mt-4 mb-3 text-3xl text-white sm:text-6xl">
                <span className="text-tusk-100">NES</span> is listing soon. Get ready.
              </span>
            </motion.div>
          ) : (
            <motion.div
              animate={{ opacity: 1 }}
              className="flex pt-2"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key="bg"
              transition={{ duration: 0.5 }}
            >
              <div className="flex flex-col">
                <div className="flex items-center text-sm font-medium text-white">
                  <Icon
                    className="mr-4 size-8 rounded-full bg-clay-900 p-2 text-tusk-100"
                    name="logo"
                  />
                  NESA AI&nbsp;<span className="text-clay-300">($NES)</span>
                </div>

                <span className="mt-2 w-[28rem] text-3xl text-white sm:text-6xl">
                  Bought and locked to date
                </span>

                <div className="mt-5 flex items-center gap-3">
                  <Icon className="size-8 rounded-md bg-tusk-100 p-2 text-black" name="arrowUp" />
                  <div className="flex h-full flex-col justify-between text-base/none text-tusk-100">
                    +4.56%
                    <span className="text-xs/none text-clay-300">Bought & Locked Change 24H</span>
                  </div>
                </div>

                <div className="mt-4 flex gap-2">
                  <div className="flex w-fit cursor-pointer items-center rounded-lg bg-clay-900 px-3 py-1.5 text-clay-300">
                    Buy&nbsp;<span className="text-white">$NES</span>
                    <Icon className="ml-2 size-4" name="arrowUpRight" />
                  </div>

                  <div className="flex w-fit cursor-pointer items-center rounded-lg border border-clay-900 px-3 py-1.5 text-sm text-white">
                    Stake Now
                    <Icon className="ml-2 size-4 text-clay-300" name="arrowUpRight" />
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-col gap-5">
                <div className="grid h-8 grid-cols-3 gap-2 sm:flex sm:gap-6">
                  {statisticsItems.map((item) => (
                    <div className="flex gap-4" key={item.title}>
                      {sm && (
                        <Icon
                          className="size-8 rounded-lg bg-clay-900 p-2 text-tusk-100"
                          name={item.icon as IconName}
                        />
                      )}
                      <div className="flex flex-col justify-between">
                        <span className="text-xs text-white sm:text-base/none">
                          {isLatestLoading ? <Spinner className="size-4" /> : item.value}
                        </span>
                        <span className="text-xs/none text-clay-380">{item.title}</span>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="h-60 w-full">
                  {isLoadingTxChart ? (
                    <div className=" flex h-44 items-center justify-center">
                      <Spinner className=" size-8" />
                    </div>
                  ) : txChart?.length ? (
                    <LineChart data={txChart} />
                  ) : (
                    <EmptyText>Empty</EmptyText>
                  )}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="mt-12 grid grid-cols-1 gap-8 px-4 sm:grid-cols-2 sm:px-16">
        <div>
          <div className="flex items-start justify-between pb-8 text-lg/none text-white sm:text-3xl/none">
            Latest Transactions
            <div
              className="flex cursor-pointer items-center text-sm text-clay-380"
              onClick={() => navigate(`/${network.chainId}/transactions`)}
            >
              View All Transactions
              <Icon className="size-4 text-white" name="arrowUpRight" />
            </div>
          </div>

          {isLatestTransactionsLoading ? (
            <Spinner className="size-7" />
          ) : (
            <DashboardTableTransactions transactionsCount={7} txs={txs} />
          )}
        </div>

        <div>
          <div className="flex items-start justify-between pb-8 text-lg/none text-white sm:text-3xl/none">
            Latest Blocks
            <div
              className="flex cursor-pointer items-center text-sm text-clay-380"
              onClick={() => navigate(`/${network.chainId}/blocks`)}
            >
              View All Blocks
              <Icon className="size-4 text-white" name="arrowUpRight" />
            </div>
          </div>
          {isLatestLoading ? (
            <Spinner className="size-7" />
          ) : (
            <DashboardTableBlocks blocksCount={7} />
          )}
        </div>
      </div>

      <div className="mt-10 px-4 sm:px-16">
        <div className="flex items-start justify-between pb-8 text-lg/none text-white sm:text-3xl/none">
          Active Proposals
          <div
            className="flex cursor-pointer items-center text-sm text-clay-380"
            onClick={() => navigate(`/${network.chainId}/governance`)}
          >
            View All Proposals
            <Icon className="size-4 text-white" name="arrowUpRight" />
          </div>
        </div>

        {(isProposalsLoading || isPoolLoading) && <Spinner className="size-7" />}
        {!isProposalsLoading && !isPoolLoading && (
          <div className="mb-10 rounded-lg bg-clay-1100">
            {filtered?.length ? (
              filtered.map((proposal) => (
                <ProposalItem key={proposal.id} poolData={poolData} proposal={proposal} />
              ))
            ) : (
              <EmptyText className="py-7">No Active Proposals yet</EmptyText>
            )}
          </div>
        )}
      </div>

      <div className="relative flex flex-col gap-10 overflow-hidden pb-9 pl-4 pt-16 sm:pl-20">
        <video
          autoPlay
          className="absolute left-0 top-0 size-full object-cover object-[100%_26%] sm:left-1/4"
          id="bg"
          loop
          muted
          playsInline
          src={bgVideo}
        ></video>

        <span className="z-10 text-3xl text-white sm:text-6xl">
          Witness the Power of <span className="text-tusk-100">Trusted AI</span> on Chain
        </span>

        <div className="z-10 grid h-8 grid-cols-3 gap-2 sm:flex sm:gap-6">
          {footerItems.map((item) => (
            <div className="flex gap-4" key={item.title}>
              {sm && (
                <Icon
                  className="size-8 rounded-lg bg-clay-900 p-2 text-white"
                  name="arrowUpRight"
                />
              )}
              <div className="flex flex-col justify-between">
                <span className="text-xs text-white sm:text-base/none">
                  {isLatestLoading ? <Spinner className="size-4" /> : item.value}
                </span>
                <span className="text-xs/none text-clay-380">{item.title}</span>
              </div>
            </div>
          ))}
        </div>

        <span className="text-xs font-light text-clay-380 ">
          Copyright 2025 Nesa AI. All rights reserved.
        </span>
      </div>
    </AnimateRoute>
  );
};
