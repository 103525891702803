import { Outlet } from "react-router-dom";

import { Header } from "../Header";

export const Layout = () => {
  return (
    <div className="bg-black">
      <Header />
      {/* <Sidebar /> */}
      <div className="h-full pt-[var(--header-height)]">
        <Outlet />
      </div>
    </div>
  );
};
