import { type ComponentPropsWithoutRef } from "react";

import { twMerge } from "tailwind-merge";

import { Spinner } from "shared/ui/Spinner";

export const TableTdTitle = (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={twMerge(
        "w-56 text-sm font-semibold text-corduroy-400 md:font-normal md:text-corduroy-900",
        props.className,
      )}
    />
  );
};

export const TableTr = (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={twMerge(
        "flex flex-col gap-3 border-t border-[#EEEFF3] px-2 py-3.5 first-of-type:border-t-0 md:flex-row md:items-center",
        props.className,
      )}
    />
  );
};

export const TableTdValue = (
  props: {
    isLoading?: boolean;
  } & ComponentPropsWithoutRef<"td">,
) => {
  const { children, isLoading, ...otherProps } = props;

  return (
    <div
      {...otherProps}
      className={twMerge(
        "flex flex-1 items-center text-sm font-normal text-corduroy-900",
        props.className,
      )}
    >
      {isLoading ? <Spinner className=" size-4" /> : children}
    </div>
  );
};

export const TableWrapper = (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={twMerge("overflow-x-auto", props.className)}>
      <div className="w-min min-w-full">{props.children}</div>
    </div>
  );
};
