import { useMemo, useState } from "react";

import { convertHash } from "shared/helpers/decode";
import { Icon } from "shared/ui/Icon";

export const HashValue = ({ hash }: { hash?: string }) => {
  const [showHash, setShowHash] = useState(true);

  const decodeHash = useMemo(() => {
    return convertHash(hash);
  }, [hash]);

  return (
    <div className="flex items-center gap-3">
      <span>{showHash ? hash : decodeHash}</span>
      {decodeHash && (
        <Icon
          className="size-[1.125rem] cursor-pointer text-primary-800 transition-all duration-200 hover:text-primary-1000"
          name="rotate"
          onClick={() => setShowHash(!showHash)}
        />
      )}
    </div>
  );
};
