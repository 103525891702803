import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { ProposalsResponse } from "./types";

import { proposalsKeys } from "./queryKeys";

type ProposalsQueryParams = {
  network?: ChainInfo;
};

export const useProposalsQuery = (
  { network = nesaTestnet }: ProposalsQueryParams = {},
  options: UseQueryOptions<ProposalsResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ProposalsResponse>(
        `${network.rest}/cosmos/gov/v1/proposals`,
        {
          params: {
            "pagination.count_total": true,
            "pagination.limit": 20,
            "pagination.reverse": true,
            proposal_status: 3,
          },
          signal,
        },
      );

      return data;
    },
    queryKey: proposalsKeys.proposalsParams({ rest: network.rest }),
    ...options,
  });
};
