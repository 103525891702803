import { useQuery } from "@tanstack/react-query";

import type { UseQueryOptions } from "shared/types";

import { axiosNodeInstance } from "shared/utils/axiosInstance";

import { transactionKeys } from "./queryKeys";

export type TotalTransactionQueryResponse = {
  total: number;
};

export const useTotalTransactionQuery = (
  options: UseQueryOptions<TotalTransactionQueryResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosNodeInstance.get<TotalTransactionQueryResponse>(
        "/transactions/total",
        {
          signal,
        },
      );
      return data;
    },
    queryKey: transactionKeys.totalTransactions(),
    ...options,
  });
};
