import { useState } from "react";

import { fromBase64, toHex } from "@cosmjs/encoding";

import { Icon } from "../Icon";

type Props = {
  value: string;
};
export const HexDecodeText = ({ value }: Props) => {
  const [isConverted, setIsConverted] = useState(false);

  return (
    <div className="flex items-center gap-1">
      {!isConverted ? value : toHex(fromBase64(value)).toUpperCase()}{" "}
      <Icon
        className="cursor-pointer text-corduroy-500 transition-colors hover:text-primary-800"
        name="rotate"
        onClick={() => setIsConverted((prev) => !prev)}
      />
    </div>
  );
};
