import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { GrazProvider } from "graz";

import { Address } from "pages/Address";
import { BlockDetails } from "pages/BlockDetails";
import { Blockchains } from "pages/Blockchains";
import { Blocks } from "pages/Blocks";
import { Dashboard } from "pages/Dashboard";
import { Governance } from "pages/Governance";
import { Proposal } from "pages/Proposal";
import { Supply } from "pages/Supply";
import { Transaction } from "pages/Transaction";
import { TransactionList } from "pages/TransactionList";
import { Uptime } from "pages/Uptime";
import { Tooltip } from "shared/ui/Tooltip";

import { nesaTestnet } from "./config/chains";
import { NetworkContextProvider } from "./providers/network";
import { Prefetch } from "./providers/prefetch";
import { Layout } from "./ui/Layout";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <NetworkContextProvider>
        <GrazProvider
          grazOptions={{
            autoReconnect: false,
            chains: [nesaTestnet],
          }}
        >
          <Tooltip.Provider>
            <Prefetch>
              <AnimatePresence>
                <Routes>
                  <Route
                    element={<Navigate replace to={`/${nesaTestnet.chainId}/dashboard`} />}
                    path="/"
                  />
                  <Route element={<Layout />} path="/:chainId">
                    <Route element={<Dashboard />} path="dashboard" />
                    <Route element={<TransactionList />} path="transactions" />
                    <Route element={<Supply />} path="supply" />
                    <Route element={<Uptime />} path="uptime" />
                    <Route element={<Governance />} path="governance" />
                    <Route element={<Proposal />} path="governance/:proposalId" />
                    <Route element={<Transaction />} path="transactions/:hash" />
                    <Route element={<Address />} path="address/:address" />
                    <Route element={<Blocks />} path="blocks" />
                    <Route element={<BlockDetails />} path="blocks/:blockHeight" />
                  </Route>
                  <Route element={<Blockchains />} path="/blockchains" />
                </Routes>
              </AnimatePresence>
            </Prefetch>
          </Tooltip.Provider>
        </GrazProvider>
        <ToastContainer />
      </NetworkContextProvider>
    </QueryClientProvider>
  );
};
