import axios from "axios";

export const axiosNodeInstance = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  headers: {
    "Access-Control-Allow-Origin": `${import.meta.env.VITE_API_ENDPOINT}`,
    "Content-Type": "application/json;charset=utf-8",
  },
  timeout: 15000,
  withCredentials: true,
});
