import type { TxBodyEncodeObject } from "@cosmjs/proto-signing";
import type { Wallet } from "graz";

import { makeSignDoc as makeSignDocAmino } from "@cosmjs/amino";
import { createWasmAminoConverters } from "@cosmjs/cosmwasm-stargate";
import { wasmTypes } from "@cosmjs/cosmwasm-stargate/build/modules";
import { fromBase64, fromBech32, toHex } from "@cosmjs/encoding";
import { Registry, makeAuthInfoBytes, makeSignDoc } from "@cosmjs/proto-signing";
import { AminoTypes, createDefaultAminoConverters, defaultRegistryTypes } from "@cosmjs/stargate";
import { PubKey } from "cosmjs-types/cosmos/crypto/secp256k1/keys";
import { SignMode } from "cosmjs-types/cosmos/tx/signing/v1beta1/signing";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { Any } from "cosmjs-types/google/protobuf/any";
import { getWallet } from "graz";

import type { Transaction } from "./getTxBytes";

const getPubKey = async (tx: Transaction, wallet: Wallet) => {
  const accounts = await wallet.getOfflineSigner(tx.chainId).getAccounts();

  const hex = toHex(fromBech32(tx.signerAddress).data);

  const accountFromSigner = accounts.find(
    (account) => toHex(fromBech32(account.address).data) === hex,
  );

  if (!accountFromSigner) {
    throw Error("No found account!");
  }

  const pubkey = Any.fromPartial({
    typeUrl: keyType(tx.chainId),
    value: PubKey.encode({
      key: accountFromSigner.pubkey,
    }).finish(),
  });

  return pubkey;
};

const getRegistry = () => {
  return new Registry([...defaultRegistryTypes, ...wasmTypes]);
};

const sendDirect = async (tx: Transaction) => {
  const wallet = getWallet();

  const txBodyEncodeObject: TxBodyEncodeObject = {
    typeUrl: "/cosmos.tx.v1beta1.TxBody",
    value: {
      memo: tx.memo,
      messages: tx.messages,
    },
  };

  const pubkey = await getPubKey(tx, wallet);

  const registry = getRegistry();

  const txBodyBytes = registry.encode(txBodyEncodeObject);

  const gasLimit = Number(tx.fee.gas);

  const authInfoBytes = makeAuthInfoBytes(
    [{ pubkey, sequence: tx.signerData.sequence }],
    tx.fee.amount,
    gasLimit,
    tx.fee.granter,
    tx.fee.payer,
  );

  const signDoc = makeSignDoc(txBodyBytes, authInfoBytes, tx.chainId, tx.signerData.accountNumber);

  const { signature, signed } = await wallet.signDirect(tx.chainId, tx.signerAddress, signDoc);

  return TxRaw.fromPartial({
    authInfoBytes: signed.authInfoBytes,
    bodyBytes: signed.bodyBytes,
    signatures: [fromBase64(signature.signature)],
  });
};

const sendAmino = async (tx: Transaction) => {
  const wallet = getWallet();

  const pubkey = await getPubKey(tx, wallet);

  const registry = getRegistry();
  const aminoTypes = new AminoTypes({
    ...createDefaultAminoConverters(),
    ...createWasmAminoConverters(),
  });

  const signMode = SignMode.SIGN_MODE_LEGACY_AMINO_JSON;
  const msgs = tx.messages.map((msg) => aminoTypes.toAmino(msg));

  const signDoc = makeSignDocAmino(
    msgs,
    tx.fee,
    tx.chainId,
    tx.memo,
    tx.signerData.accountNumber,
    tx.signerData.sequence,
  );
  const offlineSigner = wallet.getOfflineSigner(tx.chainId);

  const { signature, signed } = await offlineSigner.signAmino(tx.signerAddress, signDoc);

  const signedTxBody = {
    memo: signed.memo,
    messages: signed.msgs.map((msg) => aminoTypes.fromAmino(msg)),
  };
  const signedTxBodyEncodeObject: TxBodyEncodeObject = {
    typeUrl: "/cosmos.tx.v1beta1.TxBody",
    value: signedTxBody,
  };
  const signedTxBodyBytes = registry.encode(signedTxBodyEncodeObject);

  const signedGasLimit = Number(signed.fee.gas);
  const signedSequence = Number(signed.sequence);
  const signedAuthInfoBytes = makeAuthInfoBytes(
    [{ pubkey, sequence: signedSequence }],
    signed.fee.amount,
    signedGasLimit,
    signed.fee.granter,
    signed.fee.payer,
    signMode,
  );
  return TxRaw.fromPartial({
    authInfoBytes: signedAuthInfoBytes,
    bodyBytes: signedTxBodyBytes,
    signatures: [fromBase64(signature.signature)],
  });
};

export const sendTransactions = async (tx: Transaction) => {
  if (tx.messages.some((x) => x.typeUrl.startsWith("/cosmwasm.wasm"))) {
    return sendDirect(tx);
  }
  return sendAmino(tx);
};

export function keyType(chainId: string) {
  switch (true) {
    case chainId.search(/\w+_\d+-\d+/g) > -1: // ethermint like chain: evmos_9002-1
      return "/ethermint.crypto.v1.ethsecp256k1.PubKey";
    case chainId.startsWith("injective"):
      return "/injective.crypto.v1beta1.ethsecp256k1.PubKey";
    case chainId.startsWith("stratos"):
      return "/stratos.crypto.v1.ethsecp256k1.PubKey";
    default:
      return "/cosmos.crypto.secp256k1.PubKey";
  }
}
