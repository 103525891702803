import type { DateTime } from "luxon";

const units: Intl.RelativeTimeFormatUnit[] = [
  "year",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
];

export const timeAgo = (dateTime: DateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";

  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const detailTimeAgo = (milliseconds: number) => {
  let diff = milliseconds;
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (diff > 0 && typeof diff === "number" && !Number.isNaN(diff)) {
    days = Math.floor(diff / day);
    diff = diff % day;

    hours = Math.floor(diff / hour);
    diff = diff % hour;

    minutes = Math.floor(diff / minute);
    diff = diff % minute;

    seconds = Math.floor(diff / second);
  }

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
