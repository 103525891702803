import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { LatestBlock } from "shared/api/blocks/types";
import type { TableColumn } from "shared/ui/Table";

import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";
import { Icon } from "shared/ui/Icon";
import { Table } from "shared/ui/Table";

type Props = {
  blocksCount?: number;
};

export const DashboardTableBlocks = ({ blocksCount }: Props) => {
  const { blocksHistory } = useBlocksStore();

  const { chainId } = useParams();
  const chain = getChainById(chainId);

  const columns = useMemo(
    (): TableColumn<string, LatestBlock>[] => [
      {
        key: "latestBlock",
        renderTd: (row) => (
          <Link
            className="flex w-40 cursor-pointer items-center gap-3 border-r border-clay-850 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
            to={`/${chain.chainId}/blocks/${row.latestBlock}`}
          >
            <Icon className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300" name="box" />
            <div className="flex flex-col">
              {getFormattedNumber(row.latestBlock)}
              <span className="text-sm text-clay-500">{timeAgo(DateTime.fromISO(row.time))}</span>
            </div>
          </Link>
        ),
        tdClassName: "w-44",
      },
      {
        key: "hash",
        renderTd: (row) => (
          <div className="ml-2 truncate text-sm text-clay-500">
            <span className="mr-3 text-white">Hash</span>
            {row.hash}
          </div>
        ),
      },
      {
        key: "logo",
        renderTd: () => (
          <div className="flex items-center text-sm font-medium text-white">
            <Icon className="mr-2 size-8 p-2 text-tusk-100" name="logo" />
            Nesa
          </div>
        ),
        tdClassName: "w-28",
      },
      {
        key: "txn",
        renderTd: (row) => (
          <div className="w-full text-center text-sm text-clay-500">{row.transactions.length}</div>
        ),
        tdClassName: "w-20",
      },
    ],
    [chain.chainId],
  );

  const blocks = useMemo(() => {
    if (typeof blocksCount === "number") {
      return blocksHistory.slice(0, blocksCount);
    }
    return blocksHistory;
  }, [blocksCount, blocksHistory]);

  return (
    <div className="w-full overflow-x-auto">
      <Table className="w-full table-fixed" columns={columns} data={blocks} hovered />
    </div>
  );
};
