// /cosmos/bank/v1beta1/supply/by_denom?denom=uosmo

import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { BalancesResponse } from "./types";

import { balancesKeys } from "./keys";

type Params = {
  address?: string;
  network?: ChainInfo;
};

export const useBalancesQuery = (
  { address, network = nesaTestnet }: Params = {},
  options: UseQueryOptions<BalancesResponse> = {},
) => {
  return useQuery({
    enabled: !!address,
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<BalancesResponse>(
        `${network.rest}/cosmos/bank/v1beta1/balances/${address}`,
        { signal },
      );

      return data;
    },
    queryKey: balancesKeys.byAddress(address, { network: network.rest }),
    ...options,
  });
};
