import { toast } from "react-toastify";

import { WalletType, useSuggestChainAndConnect } from "graz";

import { nesaTestnet } from "app/config/chains";
import { Modal } from "shared/ui/Modal";

import cosmostationSrc from "./assets/cosmostation.svg";
import keplrSmLogo from "./assets/keplrSmLogo.svg";
import leapSrc from "./assets/leap.svg";
import metamaskSrc from "./assets/metamask.svg";

interface Props {
  isOpen: boolean;
  onConnected?: () => void;
  onOpenChange: (open: boolean) => void;
}

const WALLETS = [
  { image: keplrSmLogo, title: "Keplr", walletType: WalletType.KEPLR },
  { image: metamaskSrc, title: "MetaMask", walletType: WalletType.METAMASK_SNAP_LEAP },
  { image: leapSrc, title: "Leap", walletType: WalletType.LEAP },
  { image: cosmostationSrc, title: "Cosmostation", walletType: WalletType.COSMOSTATION },
];

export const WalletProviderModal = ({ isOpen: open, onConnected, onOpenChange }: Props) => {
  const isKeplrWalletInstalled = !!window.keplr;

  const { suggestAndConnectAsync } = useSuggestChainAndConnect();

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full max-w-[600px]">
        <Modal.CloseButton />
        <Modal.Title className="mb-7 font-medium">Select your wallet provider</Modal.Title>

        <div className="flex w-full flex-col gap-2">
          {WALLETS.map(({ image, title, walletType }) => {
            const isKeplr = walletType === WalletType.KEPLR;
            return (
              <div
                className="flex w-full cursor-pointer items-center gap-4 rounded-xl border border-corduroy-200 px-4 py-3 transition-colors hover:border-primary-200 hover:bg-primary-40"
                key={walletType}
                onClick={async () => {
                  try {
                    if (isKeplr && !window.keplr) {
                      toast.error(`You don't have Keplr wallet installed or enabled`);
                      return;
                    }

                    await suggestAndConnectAsync({
                      chainInfo: nesaTestnet,
                      walletType,
                    });

                    onConnected?.();
                    onOpenChange(false);
                  } catch (e) {
                    toast.error("Something went wrong");
                  }
                }}
              >
                <img className="w-12 overflow-hidden rounded-md" src={image} />

                <div className="font-medium text-corduroy-700">
                  {title}
                  {isKeplr && !isKeplrWalletInstalled && " (uninstalled)"}
                  {isKeplr && !isKeplrWalletInstalled && (
                    <div className="mt-0.5 w-full text-left text-xs/none font-normal text-corduroy-400">
                      {`if the extension is installed, you must make sure it's enabled in incognito mode`}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Content>
    </Modal>
  );
};
