import { StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { createRoot } from "react-dom/client";

import { App } from "./app/App.tsx";

import "./index.css";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>,
);
