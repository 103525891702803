import { Tooltip } from "shared/ui/Tooltip";

type Props = {
  blocks: { color: string; height: string }[];
};

export const UptimeBar = ({ blocks }: Props) => {
  return (
    <div className="flex justify-between gap-0.5">
      {blocks.map((block, i) => {
        return (
          <Tooltip content={<div>{block.height}</div>} key={block.height + i} side="top">
            <div
              className="h-6 w-[3px] cursor-pointer bg-corduroy-400 hover:brightness-75"
              key={block.height + i}
              style={{ backgroundColor: block.color }}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
};
