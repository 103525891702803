import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { LatestBlock } from "shared/api/blocks/types";
import type { TableColumn } from "shared/ui/Table";

import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getValidatorCosmosByAddress } from "features/useValidatorsCosmos";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";
import { Table } from "shared/ui/Table";

type Props = {
  blocksCount?: number;
  withHeader?: boolean;
};

export const TableBlocks = ({ blocksCount, withHeader = true }: Props) => {
  const { blocksHistory } = useBlocksStore();

  const { chainId } = useParams();
  const chain = getChainById(chainId);

  const columns = useMemo(
    (): TableColumn<string, LatestBlock>[] => [
      {
        key: "latestBlock",
        renderTd: (row) => (
          <Link
            className="cursor-pointer text-sm font-semibold text-primary-900 transition-all duration-200 hover:brightness-90"
            to={`/${chain.chainId}/blocks/${row.latestBlock}`}
          >
            {getFormattedNumber(row.latestBlock)}
          </Link>
        ),
        title: withHeader ? "Block" : undefined,
      },
      {
        key: "proposer",
        renderTd: (row) => (
          <span className="text-sm font-medium text-primary-900">
            {getValidatorCosmosByAddress(row.proposerAddress)?.moniker || row.proposerAddress}
          </span>
        ),
        title: withHeader ? "Proposer" : undefined,
      },
      {
        key: "time",
        renderTd: (row) => (
          <span className="text-sm text-corduroy-700">{timeAgo(DateTime.fromISO(row.time))}</span>
        ),
        title: withHeader ? "Time" : undefined,
      },
      {
        key: "hash",
        renderTd: (row) => <span className="text-sm text-corduroy-700">{row.hash}</span>,
        title: withHeader ? "# Hash" : undefined,
      },
      {
        key: "txn",
        renderTd: (row) => (
          <span className="text-sm text-corduroy-700">{row.transactions.length}</span>
        ),
        title: withHeader ? "Txn" : undefined,
      },
    ],
    [chain.chainId, withHeader],
  );

  const blocks = useMemo(() => {
    if (typeof blocksCount === "number") {
      return blocksHistory.slice(0, blocksCount);
    }
    return blocksHistory;
  }, [blocksCount, blocksHistory]);

  return (
    <div className="w-full overflow-x-auto">
      <Table className="min-w-[64rem]" columns={columns} data={blocks} hovered />
    </div>
  );
};
