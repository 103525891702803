import { type PropsWithChildren, useState } from "react";

import * as SelectPrimitive from "@radix-ui/react-select";
import { twJoin, twMerge } from "tailwind-merge";

import type { ClassName } from "shared/types";

import { Icon } from "../Icon";
import { Label } from "../Label";

type Size = {
  color?: "asInput" | "gray";
  size?: "medium" | "small";
};

type Props = { label?: string; placeholder?: string } & ClassName &
  SelectPrimitive.SelectProps &
  Size;

export const Select = ({
  children,
  className,
  color = "asInput",
  defaultOpen,
  disabled,
  label,
  onOpenChange: onOpenChangeProp,
  open: openProp,
  placeholder,
  size = "small",
  value,
  ...props
}: Props) => {
  const [open, setOpen] = useState(defaultOpen);

  const isControl = typeof openProp === "boolean" && typeof onOpenChangeProp === "function";

  const isOpen = isControl ? openProp : open;
  const onOpenChange = isControl ? onOpenChangeProp : setOpen;

  return (
    <SelectPrimitive.Root
      {...props}
      disabled={disabled}
      key={value}
      onOpenChange={onOpenChange}
      open={isOpen}
      value={value}
    >
      <div className="flex flex-col">
        {label && <Label>{label}</Label>}
        <SelectPrimitive.Trigger
          className={twMerge(
            "flex w-full items-center rounded-lg px-3 py-2 outline-none transition-shadow focus:outline-none xs:p-3",
            size === "small" && "h-10 text-xs",
            size === "medium" && "h-12 text-xs lg:text-sm",
            color === "asInput" &&
              "bg-white inner-border inner-border-corduroy-200 hover:inner-border-primary-900 ",
            color === "gray" && "bg-corduroy-100 text-corduroy-700 hover:brightness-95",
            disabled && "pointer-events-none cursor-not-allowed bg-corduroy-200",
            className,
          )}
          onClick={() => onOpenChange(true)}
          onPointerDown={(e) => e.preventDefault()}
        >
          {value ? (
            <SelectPrimitive.Value />
          ) : (
            <span className="text-corduroy-400">{placeholder}</span>
          )}
          <SelectPrimitive.Icon className="ml-auto">
            <Icon
              className={twJoin("size-6 p-0", disabled ? "text-corduroy-400" : "text-corduroy-700")}
              name="arrowDownSm"
            />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
      </div>

      {children}
    </SelectPrimitive.Root>
  );
};

const SelectContent = ({
  children,
  className,
  ...restProps
}: SelectPrimitive.SelectContentProps) => {
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        position="popper"
        sideOffset={4}
        {...restProps}
        className={twMerge(
          "max-h-[var(--radix-select-content-available-height)] min-w-[var(--radix-select-trigger-width)] max-w-[var(--radix-select-content-available-width)]",
          "z-40 overflow-hidden rounded-lg bg-white inner-border inner-border-corduroy-200",
          className,
        )}
      >
        <SelectPrimitive.ScrollUpButton>
          <Icon className="mx-auto size-6 rotate-180 text-corduroy-500" name="arrowDownSm" />
        </SelectPrimitive.ScrollUpButton>
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
        <SelectPrimitive.ScrollDownButton>
          <Icon className="mx-auto size-6 text-corduroy-500" name="arrowDownSm" />
        </SelectPrimitive.ScrollDownButton>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
};

const SelectItem = ({
  children,
  className,
  ...props
}: PropsWithChildren<SelectPrimitive.SelectItemProps>) => {
  return (
    <SelectPrimitive.Item
      {...props}
      className={twMerge(
        "flex w-full cursor-pointer items-center justify-between px-3 py-2 text-xs transition-colors hover:bg-primary-900/30 focus:outline-none lg:text-sm",
        className,
      )}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <SelectPrimitive.ItemIndicator>
        <Icon className="text-primary-1000" name="check" />
      </SelectPrimitive.ItemIndicator>
    </SelectPrimitive.Item>
  );
};

Select.Item = SelectItem;
Select.Content = SelectContent;
