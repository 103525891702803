import type { ChainInfo } from "@keplr-wallet/types";

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { useLastTransactionInfiniteQuery } from "shared/api/transactions/useLastTransaction";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { Button } from "shared/ui/Button";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";

type Props = {
  network: ChainInfo;
};

export const TransactionListContent = ({ network }: Props) => {
  const navigate = useNavigate();

  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useLastTransactionInfiniteQuery({
    limit: 20,
  });

  const lastTransactions = useMemo(() => (data ? data.pages.flat() : null), [data]);

  const columns: TableColumn<string, TransactionQueryResponse>[] = useMemo(
    () => [
      {
        key: "height",
        renderTd: (row) => {
          return (
            <div
              className="cursor-pointer font-medium text-primary-800 transition-colors hover:text-primary-1000"
              onClick={() => navigate(`/${network.chainId}/blocks/${row.height}`)}
            >
              {row.tx_response.height}
            </div>
          );
        },
        title: "Block",
      },
      {
        key: "hash",
        renderTd: (row) => {
          return (
            <div
              className="col-span-3 cursor-pointer truncate text-primary-900 transition-colors hover:text-primary-1000"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              {row.txhash}
            </div>
          );
        },
        title: "Hash",
      },
      {
        key: "message",
        renderTd: (row) => {
          return (
            <div className="max-w-48 truncate">
              {getTxMessage(row.tx.body.messages as TxResponse["tx"]["body"]["messages"])}
            </div>
          );
        },
        title: "Message",
      },
      {
        key: "fees",
        renderTd: (row) => {
          const amount = row.tx.auth_info.fee?.amount?.[0];
          if (!amount) return null;
          return (
            <div>
              {getFormattedNumber(amount.amount)} {amount.denom}
            </div>
          );
        },
        title: "Fees",
      },
    ],
    [navigate, network.chainId],
  );

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center py-8">
        <Spinner className="size-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 w-full overflow-x-auto p-6 pb-0">
        <Table columns={columns} data={lastTransactions || []} />
      </div>

      <div className="flex items-center justify-center">
        <Button
          className="mx-auto"
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
          size="medium"
        >
          Load More
        </Button>
      </div>
    </div>
  );
};
