import { nesaTestnet } from "app/config/chains";

export type Network = "mainnet" | "testnet";

export const getBaseEndpoints = (network: Network = "testnet") => {
  if (network === "testnet") return nesaTestnet.rest;
};

export const getNetwork = (network: Network = "testnet") => {
  if (network === "testnet") return nesaTestnet;
};
