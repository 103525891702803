import { useEffect } from "react";

import { BigNumber } from "bignumber.js";
import { create } from "zustand";

import type { LatestBlock } from "shared/api/blocks/types";

import { useBlockByHeightMutation } from "shared/api/blocks/useBlockByHeightQuery";
import { useLatestBlockQuery } from "shared/api/blocks/useLatestBlockQuery";

type BlocksStore = {
  blocksHistory: LatestBlock[];
  setBlocksHistory: (latestBlock: LatestBlock) => void;
};

export const useBlocksStore = create<BlocksStore>((set) => ({
  blocksHistory: [],
  setBlocksHistory: (latestBlock) => {
    set((prev) => ({ blocksHistory: [...prev.blocksHistory, latestBlock] }));
  },
}));

export const useGlobalLatestBlocks = () => {
  const { data: latestBlock, status } = useLatestBlockQuery(
    {},
    { refetchInterval: undefined, refetchOnWindowFocus: false },
  );

  const { mutateAsync } = useBlockByHeightMutation();

  useEffect(() => {
    if (status !== "success" || !latestBlock) return;

    let latestBlockHeight = latestBlock.latestBlock;

    useBlocksStore.setState({ blocksHistory: [latestBlock] });

    let timeoutId: number;
    (async () => {
      const fetchBlock = () => {
        timeoutId = setTimeout(async () => {
          try {
            const nextLatestBlockHeight = new BigNumber(latestBlockHeight).plus(1).toString();
            const byHeight = await mutateAsync(nextLatestBlockHeight);

            latestBlockHeight = nextLatestBlockHeight;
            useBlocksStore.setState((prev) => {
              const newBlocks = [byHeight, ...prev.blocksHistory];
              if (newBlocks.length > 100) {
                newBlocks.pop();
              }
              return {
                blocksHistory: newBlocks,
              };
            });

            fetchBlock();
          } catch {
            fetchBlock();
          }
        }, 3000);
      };

      const result = [latestBlock];

      for (let i = 1; i < 7; i++) {
        const block = await mutateAsync(new BigNumber(latestBlockHeight).minus(i + 1).toString());
        result.push(block);
      }
      useBlocksStore.setState({ blocksHistory: result });

      fetchBlock();
    })();

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
};
