import { useCallback, useMemo, useState } from "react";

import { DateTime } from "luxon";
import {
  Bar,
  CartesianGrid,
  BarChart as RBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import type { LatestBlock } from "shared/api/blocks/types";

import { theme } from "app/theme";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";

type Props = {
  latestBlocks: LatestBlock[];
};

type DataChart = {
  block: null | string;
  time: null | string;
  transactionsCount: null | number;
} | null;

export const BarChart = ({ latestBlocks }: Props) => {
  const [yAxisWidth, setYAxisWidth] = useState(20);

  const data = useMemo(() => {
    const blocks: DataChart[] = [];
    for (let i = 35; i >= 0; i--) {
      if (latestBlocks[i]) {
        blocks.push({
          block: latestBlocks[i].latestBlock,
          time: latestBlocks[i].time,
          transactionsCount: latestBlocks[i].transactions.length,
        });
      }
    }
    if (blocks.length < 36) {
      blocks.push(...new Array(36 - blocks.length).fill(null));
    }
    return blocks;
  }, [latestBlocks]);

  const tickFormatter = useCallback((transactionsCount: number) => {
    const label = `${transactionsCount}`;
    const width = label.length * 12;
    setTimeout(() => setYAxisWidth((prev) => (prev > width ? prev : width)));

    return label;
  }, []);

  return (
    <div className="mb-5 h-40 rounded-xl bg-white px-3 pb-2 pt-5 shadow-md sm:px-5">
      <ResponsiveContainer>
        <RBarChart data={data} margin={{ bottom: 0, left: 0, right: 0, top: 12 }}>
          <YAxis
            axisLine={false}
            dataKey="transactionsCount"
            domain={data.length ? undefined : [0, 4]}
            minTickGap={0}
            tick={{ color: theme.colors.corduroy[700], fontFamily: "Inter", fontSize: 12 }}
            tickCount={5}
            tickFormatter={tickFormatter}
            tickLine={false}
            width={yAxisWidth}
          />
          <XAxis axisLine={false} tick={false} tickLine={false} />

          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: theme.colors.corduroy[100], opacity: 0.4, stroke: "transparent" }}
            wrapperStyle={{ outline: "none" }}
          />
          <CartesianGrid stroke={theme.colors.corduroy[100]} vertical={false} />
          <Bar dataKey="transactionsCount" fill={theme.colors.primary[800]} radius={2} />
        </RBarChart>
      </ResponsiveContainer>
    </div>
  );
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: [{ payload: DataChart }];
};

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  const data = payload?.[0]?.payload;
  if (!data || !active || !data.block || !data.time) return null;

  return (
    <div className="grid grid-cols-1 gap-2 rounded-md bg-white p-1.5 shadow-md">
      <div className="flex items-center justify-between gap-3">
        <span className="text-xs font-medium text-corduroy-600">Block:</span>
        <span className="text-xs font-semibold text-corduroy-900">
          {getFormattedNumber(data.block || undefined)}
        </span>
      </div>

      <div className="flex items-center justify-between gap-3">
        <span className="text-xs font-medium text-corduroy-600">Txn:</span>
        <span className="text-xs font-semibold text-corduroy-900">{data.transactionsCount}</span>
      </div>

      <div className="flex items-center justify-between gap-3">
        <span className="text-xs font-medium text-corduroy-600">Time:</span>
        <span className="text-xs font-semibold text-corduroy-900">
          {timeAgo(DateTime.fromISO(data.time))}
        </span>
      </div>
    </div>
  );
};
