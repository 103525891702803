import { useNavigate } from "react-router-dom";

import { nesaTestnet } from "app/config/chains";
import { useNetwork } from "app/providers/network";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import coverSrc from "shared/ui/assets/cover.png";

import { NetworkCard } from "./ui/NetworkCard";

export const Blockchains = () => {
  const navigate = useNavigate();
  const { onChange: onNetworkChange } = useNetwork();

  return (
    <AnimateRoute className="flex flex-col p-0">
      <div
        className="flex min-h-44 w-full flex-col items-center justify-center gap-2 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${coverSrc})` }}
      >
        <div className="mx-2 select-none text-center text-2xl/none text-primary-900 md:text-4xl">
          NESA Network
        </div>
        <p className="text-center text-xs font-light text-white/80 sm:text-sm md:text-base">
          The Layer-1 Blockchain for AI
        </p>
      </div>

      <div className="px-4 py-2 md:p-6">
        <div className="mb-6 flex h-10 items-center border-b border-corduroy-200 md:h-14">
          <h2 className="text-sm md:text-base">All Blockchain</h2>
        </div>

        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-6">
          <NetworkCard
            network={nesaTestnet}
            onClick={() => {
              onNetworkChange(nesaTestnet);
              navigate(`/${nesaTestnet.chainId}/dashboard`);
            }}
          />
        </div>
      </div>
    </AnimateRoute>
  );
};
