import { useMemo } from "react";
import { useParams } from "react-router-dom";

import type { Balance } from "shared/api/balances";
import type { StakingParamsResponse } from "shared/api/staking";

import { useGetValidatorsQuery } from "shared/api/validators/useGetValidatorsQuery";
import { getChainById } from "shared/helpers/getChainById";
import { decimal2percent, getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { Input } from "shared/ui/Input";
import { Label } from "shared/ui/Label";
import { Select } from "shared/ui/Select";

type Props = {
  amountValue: string;
  balances: Balance[];
  params: StakingParamsResponse;
  senderValue: string;
  setAmountValue: React.Dispatch<React.SetStateAction<string>>;
  setSenderValue: React.Dispatch<React.SetStateAction<string>>;
  setValidatorValue: React.Dispatch<React.SetStateAction<string>>;
  validator: string | undefined;
};

export const DelegateForm = ({
  amountValue,
  balances,
  params,
  senderValue,
  setAmountValue,
  setSenderValue,
  setValidatorValue,
  validator,
}: Props) => {
  const { chainId } = useParams<{ chainId: string }>();

  const network = getChainById(chainId || "");

  const { data: validators, status: validatorsStatus } = useGetValidatorsQuery({
    limit: 500,
    network,
    status: "BOND_STATUS_BONDED",
  });

  const balance = useMemo(() => {
    const balance = balances.find((el) => el.denom === params?.params.bond_denom);
    return balance;
  }, [balances, params]);

  const available = balance ? getFormattedNumber(balance?.amount) : "0";

  return (
    <>
      <Label>
        <div className="mb-1">Sender</div>
        <Input onChange={(e) => setSenderValue(e.target.value)} value={senderValue} />
      </Label>

      {validatorsStatus === "success" && (
        <Label>
          <div className="mb-1">Validator</div>
          <Select onValueChange={setValidatorValue} value={validator}>
            <Select.Content>
              {validators?.validators.map((el) => (
                <Select.Item key={el.operator_address} value={el.operator_address}>
                  {el.description.moniker} ({decimal2percent(el.commission.commission_rates.rate)})%
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </Label>
      )}

      <Label>
        <div className="mb-1 flex justify-between">
          <div>Amount</div>
          <div>
            {available} {balance?.denom || ""}
          </div>
        </div>
        <Input
          onChange={(e) => setAmountValue(e.target.value)}
          placeholder={`Available: ${available}`}
          type="number"
          value={amountValue}
        />
      </Label>
    </>
  );
};
