import type { ChainInfo } from "@keplr-wallet/types";

import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import type { UseMutationOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

export type TransactionMutateRequest = {
  mode: string;
  tx_bytes: string;
};

export type TransactionMutateResponse = {
  code: 0;
  codespace: string;
  data: string;
  height: string;
  raw_log: string;
  txhash: string;
};

type Params = {
  network?: ChainInfo;
};

export const useTransactionMutation = (
  { network = nesaTestnet }: Params = {},
  options: UseMutationOptions<
    { tx_response: TransactionMutateResponse },
    unknown,
    TransactionMutateRequest
  > = {},
) => {
  return useMutation({
    mutationFn: async (payload) => {
      const { data } = await axios.post<{ tx_response: TransactionMutateResponse }>(
        `${network.rest}/cosmos/tx/v1beta1/txs`,
        payload,
      );

      return data;
    },
    ...options,
  });
};
