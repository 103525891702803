import type { ChainInfo } from "@keplr-wallet/types";

import logoSrc from "app/assets/images/logo.svg";

type Props = {
  network: ChainInfo;
  onClick: () => void;
};

export const NetworkCard = ({ network, onClick }: Props) => {
  return (
    <div
      className="w-full cursor-pointer overflow-hidden rounded-md border border-transparent bg-white shadow-md transition-colors hover:border-primary-900"
      onClick={onClick}
    >
      <div className="flex items-center justify-center bg-primary-50 px-6 py-4">
        <img alt="logo" className="size-16" src={logoSrc} />
      </div>

      <div className="flex flex-col items-center justify-center p-4">
        <div className="text-center text-base">{network.chainName}</div>
      </div>
    </div>
  );
};
