import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { StakingPoolResponse } from "./types";

import { stakingKeys } from "./queryKeys";

type Params = {
  network?: ChainInfo;
};

export const useStakingPoolQuery = (
  { network = nesaTestnet }: Params = {},
  options: UseQueryOptions<StakingPoolResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<StakingPoolResponse>(
        `${network.rest}/cosmos/staking/v1beta1/pool`,
        { signal },
      );

      return data;
    },
    queryKey: stakingKeys.params({ rest: network.rest }),
    refetchInterval: 30 * 1000, // 30 seconds
    ...options,
  });
};
