import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { useAccount } from "graz";
import { DateTime } from "luxon";
import { twJoin } from "tailwind-merge";

import { TxDialog } from "features/TxDialog";
import { WalletProviderModal } from "features/WalletProviderModal";
import { statusMap } from "pages/Governance/config";
import { useProposalsQuery } from "shared/api/proposals";
import { useStakingParamsQuery } from "shared/api/staking/useStakingParamsQuery";
import { useStakingPoolQuery } from "shared/api/staking/useStakingPoolQuery";
import { getChainById } from "shared/helpers/getChainById";
import { detailTimeAgo, timeAgo } from "shared/helpers/timeAgo";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Button } from "shared/ui/Button";
import { Card } from "shared/ui/Card";
import { TableTdTitle, TableTdValue, TableTr, TableWrapper } from "shared/ui/ObjectTable";
import { ProgressLine } from "shared/ui/ProgressLine";
import { SmallTable } from "shared/ui/Table";

import { proposalColumns } from "./config";

export const Proposal = () => {
  const [openVoteModal, setOpenVoteModal] = useState(false);
  const [isProviderOpen, setIsProviderOpen] = useState(false);

  const { chainId, proposalId } = useParams<{ chainId: string; proposalId: string }>();
  const network = getChainById(chainId || "");

  const { data: proposals, status: proposalsStatus } = useProposalsQuery({ network });
  const { data: poolData, status: poolDataStatus } = useStakingPoolQuery({ network });
  const { data: stakingParams, status: stakingParamsStatus } = useStakingParamsQuery();

  const account = useAccount();

  if (!proposalId) return <Navigate to={`/${chainId}/governance`} />;

  const isLoading = proposalsStatus === "pending" || poolDataStatus === "pending";

  const proposal = proposals?.proposals.find((el) => el.id === proposalId);

  if (proposalsStatus === "error" || (!isLoading && !proposal)) {
    return (
      <Card>
        <Card.Content className="py-10 text-center">
          <div className="mt-3 text-base text-corduroy-900">Something went wrong</div>
        </Card.Content>
      </Card>
    );
  }

  const total = (() => {
    const tally = proposal?.final_tally_result;
    let sum = 0;
    if (tally) {
      sum += Number(tally.abstain_count || 0);
      sum += Number(tally.yes_count || 0);
      sum += Number(tally.no_count || 0);
      sum += Number(tally.no_with_veto_count || 0);
    }
    return sum;
  })();

  const turnout = (() => {
    if (total > 0) {
      const bonded = poolData?.pool.bonded_tokens || "1";
      return calculatePercent(total, bonded);
    }
    return 0;
  })();
  const yes = (() => {
    if (total > 0) {
      const yes = proposal?.final_tally_result?.yes_count || 0;
      return calculatePercent(yes, total);
    }
    return 0;
  })();
  const no = (() => {
    if (total > 0) {
      const no = proposal?.final_tally_result?.no_count || 0;
      return calculatePercent(no, total);
    }
    return 0;
  })();
  const veto = (() => {
    if (total > 0) {
      const veto = proposal?.final_tally_result?.no_with_veto_count || 0;
      return calculatePercent(veto, total);
    }
    return 0;
  })();
  const abstain = (() => {
    if (total > 0) {
      const abstain = proposal?.final_tally_result?.abstain_count || 0;
      return calculatePercent(abstain, total);
    }
    return 0;
  })();

  const votingCountdown = (() => {
    const end = DateTime.fromISO(proposal?.voting_end_time || "");
    return detailTimeAgo(end.toMillis() - Date.now());
  })();

  const isDisabledVote =
    DateTime.fromISO(proposal?.voting_end_time || "")
      .toLocal()
      .toMillis() < DateTime.now().toMillis();

  return (
    <AnimateRoute className="grid grid-cols-1 gap-5 p-2 md:p-6">
      <Card>
        <Card.BaseTitle className="flex flex-wrap items-center justify-between gap-2 pb-0">
          <span>
            #{proposalId}. {proposal?.title}
          </span>

          {proposal && (
            <div
              className={twJoin(
                "text-xs",
                statusMap[proposal.status] === "PASSED" && "text-primary-800",
                statusMap[proposal.status] === "VOTING" && "text-pink-500",
                statusMap[proposal.status] === "REJECTED" && "text-yellow-500",
              )}
            >
              {statusMap[proposal.status] || proposal.status}
            </div>
          )}
        </Card.BaseTitle>
        <Card.Content>
          <TableWrapper>
            <TableTr>
              <TableTdTitle>@Type</TableTdTitle>
              <TableTdValue isLoading={proposalsStatus === "pending"}>
                {proposal?.messages[0]["@type"]}
              </TableTdValue>
            </TableTr>
            <TableTr>
              <TableTdTitle>Authority</TableTdTitle>
              <TableTdValue isLoading={proposalsStatus === "pending"}>
                {proposal?.messages[0].authority}
              </TableTdValue>
            </TableTr>
            <TableTr>
              <TableTdTitle>Params</TableTdTitle>
              <TableTdValue isLoading={proposalsStatus === "pending"}>
                <SmallTable
                  columns={proposalColumns}
                  data={proposal?.messages.map((el) => el.params) || []}
                />
              </TableTdValue>
            </TableTr>
            <TableTr>
              <TableTdTitle>Current</TableTdTitle>
              <TableTdValue isLoading={stakingParamsStatus === "pending"}>
                <SmallTable
                  columns={proposalColumns}
                  data={stakingParams ? [stakingParams.params] : []}
                />
              </TableTdValue>
            </TableTr>
          </TableWrapper>
        </Card.Content>
      </Card>

      <Card>
        <Card.BaseTitle className="pb-0">Tally</Card.BaseTitle>
        <Card.Content className=" grid grid-cols-1 gap-3">
          <div>
            <div className="mb-1.5 text-sm font-medium text-corduroy-600">Turnout</div>
            <ProgressLine classNameLine="bg-[#00b2ff]" percent={turnout} />
          </div>
          <div>
            <div className="mb-1.5 text-sm font-medium text-corduroy-600">Yes</div>
            <ProgressLine classNameLine="bg-primary-800" percent={yes} />
          </div>
          <div>
            <div className="mb-1.5 text-sm font-medium text-corduroy-600">No</div>
            <ProgressLine classNameLine="bg-pink-500/20" percent={no} />
          </div>
          <div>
            <div className="mb-1.5 text-sm font-medium text-corduroy-600">No With Veto</div>
            <ProgressLine classNameLine="bg-pink-500/40" percent={veto} />
          </div>
          <div>
            <div className="mb-1.5 text-sm font-medium text-corduroy-600">Abstain</div>
            <ProgressLine classNameLine="bg-yellow-500/60" percent={abstain} />
          </div>
          {/* {isDisabledVote && } */}
          <div className="mt-4 gap-5">
            <Button
              className=" w-56"
              color="primary"
              disabled={isDisabledVote}
              isLoading={account.isConnecting}
              onClick={() => {
                if (account.isConnected) {
                  setOpenVoteModal(true);
                } else {
                  setIsProviderOpen(true);
                }
              }}
              size="medium"
            >
              {isDisabledVote
                ? `Vote ended: ${DateTime.fromISO(proposal?.voting_end_time || "").toLocaleString()}`
                : "Vote"}
            </Button>
          </div>
        </Card.Content>
      </Card>

      {proposal && (
        <Card>
          <Card.BaseTitle className="pb-0">Timeline</Card.BaseTitle>
          <Card.Content>
            <div className="mb-4 flex items-center">
              <div className="mr-3 size-2 rounded-full bg-red-800/50" />
              <div className="flex-1 text-base text-corduroy-900">
                Submited at: {DateTime.fromISO(proposal.submit_time || "").toLocaleString()}
              </div>
              <div className="text-sm text-corduroy-700">
                {proposal.submit_time ? timeAgo(DateTime.fromISO(proposal.submit_time)) : ""}
              </div>
            </div>

            <div className="mb-4 flex items-center">
              <div className="mr-3 size-2 rounded-full bg-primary-800" />
              <div className="flex-1 text-base text-corduroy-900">
                Deposited at:{" "}
                {DateTime.fromISO(
                  proposal.status === "PROPOSAL_STATUS_DEPOSIT_PERIOD"
                    ? proposal.deposit_end_time
                    : proposal.voting_start_time,
                ).toLocaleString()}
              </div>
              <div className="text-sm text-corduroy-700">
                {timeAgo(
                  DateTime.fromISO(
                    proposal.status === "PROPOSAL_STATUS_DEPOSIT_PERIOD"
                      ? proposal.deposit_end_time
                      : proposal.voting_start_time,
                  ),
                )}
              </div>
            </div>

            <div className="mb-1 flex items-center">
              <div className="mr-3 size-2 rounded-full bg-primary-800" />
              <div className="flex-1 text-base text-corduroy-900">
                Voting start from: {DateTime.fromISO(proposal.voting_start_time).toLocaleString()}
              </div>
              <div className="text-sm text-corduroy-700">
                {timeAgo(DateTime.fromISO(proposal.voting_start_time))}
              </div>
            </div>
            <div className="mb-4 flex items-baseline gap-2 pl-5 text-sm">
              <div>
                <span className="text-base font-semibold text-primary-900">
                  {votingCountdown.days}
                </span>{" "}
                days
              </div>
              <div>
                <span className="text-base font-semibold text-primary-900">
                  {votingCountdown.days}
                </span>{" "}
                days
              </div>
              <div>
                <span className="text-base font-semibold text-primary-900">
                  {votingCountdown.hours}
                </span>{" "}
                hours
              </div>
              <div>
                <span className="text-base font-semibold text-primary-900">
                  {votingCountdown.minutes}
                </span>{" "}
                minutes
              </div>
              <div>
                <span className="text-base font-semibold text-primary-900">
                  {votingCountdown.seconds}
                </span>{" "}
                seconds
              </div>
            </div>

            <div className="mb-1 flex items-center">
              <div className="mr-3 size-2 rounded-full bg-primary-800" />
              <div className="flex-1 text-base text-corduroy-900">
                Voting end: {DateTime.fromISO(proposal.voting_end_time || "").toLocaleString()}
              </div>
              <div className="text-sm text-corduroy-700">
                {proposal.voting_end_time
                  ? timeAgo(DateTime.fromISO(proposal.voting_end_time))
                  : ""}
              </div>
            </div>
            <div className="pl-5">
              <div className="text-sm text-corduroy-900">
                Current Status: {statusMap[proposal.status]}
              </div>
            </div>

            {/* {proposalType?.endsWith("SoftwareUpgradeProposal") && (
              <div className="mt-4 flex items-center">
                <div className="mr-3 size-2 rounded-full bg-yellow-500" />
                <div className="flex-1 text-base text-corduroy-900">
                  Upgrade Plan:{" "}
                  {DateTime.fromISO(proposal.total_deposit || "").toFormat("yyyy-LL-dd HH:mm")}
                </div>
                <div className="text-sm text-corduroy-700">
                  {proposal.submit_time ? timeAgo(DateTime.fromISO(proposal.submit_time)) : ""}
                </div>
              </div>
            )} */}
          </Card.Content>
        </Card>
      )}

      {/* <Card>
        <Card.BaseTitle className="pb-0">Votes</Card.BaseTitle>
      </Card> */}

      <WalletProviderModal
        isOpen={isProviderOpen}
        onConnected={() => setOpenVoteModal(true)}
        onOpenChange={setIsProviderOpen}
      />

      {account.data && (
        <TxDialog
          isOpen={openVoteModal}
          onOpenChange={setOpenVoteModal}
          proposalId={proposal?.id}
          sender={account.data?.bech32Address}
          title="Vote"
          type="vote"
        />
      )}
    </AnimateRoute>
  );
};

function calculatePercent(input?: number | string, total?: number | string) {
  if (!input || !total) return 0;
  const percent = Number(input) / Number(total);

  return Math.ceil((percent > 0.0001 ? percent : 0) * 100);
}
