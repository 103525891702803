import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { StakingParamsResponse } from "./types";

import { stakingKeys } from "./queryKeys";

type Params = {
  network?: ChainInfo;
};

export const useStakingParamsQuery = (
  { network = nesaTestnet }: Params = {},
  options: UseQueryOptions<StakingParamsResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<StakingParamsResponse>(
        `${network.rest}/cosmos/staking/v1beta1/params`,
        { signal },
      );

      return data;
    },
    queryKey: stakingKeys.stakingParams({ rest: network.rest }),
    ...options,
  });
};
