export const stakingKeys = {
  base: ["staking"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: (params: Record<string, any>) => [...stakingKeys.base, "latest", params],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stakingParams: (params: Record<string, any>) => [...stakingKeys.base, "params", params],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unbondingDelegations: (params: Record<string, any>) => [
    ...stakingKeys.base,
    "unbonding_delegations",
    params,
  ],
};
