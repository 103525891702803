import { Input } from "shared/ui/Input";
import { Label } from "shared/ui/Label";
import { RadioGroup } from "shared/ui/Radio";

type Props = {
  senderValue: string;
  setSenderValue: React.Dispatch<React.SetStateAction<string>>;
  setVoteValue: React.Dispatch<React.SetStateAction<string>>;
  voteValue: string;
};

const radioButtons = [
  { label: "Yes", value: "1" },
  { label: "No", value: "3" },
  { label: "No With Veto", value: "4" },
  { label: "Abstain", value: "2" },
];

export const VoteForm = ({ senderValue, setSenderValue, setVoteValue, voteValue }: Props) => {
  return (
    <>
      <Label>
        <div className="mb-1">Sender</div>
        <Input onChange={(e) => setSenderValue(e.target.value)} value={senderValue} />
      </Label>

      <div className="mb-1">
        <Label>Option</Label>
        <RadioGroup className="mt-1 flex flex-wrap gap-3" value={voteValue}>
          {radioButtons.map((el) => (
            <RadioGroup.Item
              // itemClassName={twJoin(el.value === radioValue && "border-primary-900")}
              key={el.value}
              onClick={() => setVoteValue(el.value)}
              value={el.value}
            >
              {el.label}
            </RadioGroup.Item>
          ))}
        </RadioGroup>
      </div>
    </>
  );
};
