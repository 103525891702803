import { TableBlocks } from "features/TableBlocks";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import coverSrc from "shared/ui/assets/cover.png";

import { BarChart } from "./ui/BarChart";

export const Blocks = () => {
  const { blocksHistory } = useBlocksStore();

  return (
    <AnimateRoute className="relative flex flex-col p-0 pb-5">
      <div className="mx-auto w-full max-w-7xl px-2 sm:px-5">
        <img
          alt=""
          className="absolute left-0 top-0 -z-10 h-44 w-full object-cover"
          src={coverSrc}
        />

        <div className="my-5 text-center">
          <h2 className="mb-1 text-lg font-semibold text-white">Latest Blocks</h2>
          <p className="text-xs text-corduroy-300">
            Total of{" "}
            {blocksHistory[0]?.latestBlock
              ? getFormattedNumber(blocksHistory[0]?.latestBlock)
              : "-"}{" "}
            blocks
          </p>
        </div>

        <BarChart latestBlocks={blocksHistory} />

        <TableBlocks />
      </div>
    </AnimateRoute>
  );
};
