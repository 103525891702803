import type { ComponentProps } from "react";
import { useId } from "react";

import * as Checkbox from "@radix-ui/react-checkbox";
import { twMerge } from "tailwind-merge";

import { Icon } from "../Icon";

type Props = ComponentProps<typeof Checkbox.Root>;

const CheckboxUI = ({ checked, children, className, id, ...props }: Props) => {
  const idEl = useId();
  return (
    <div className="flex items-center gap-2">
      <Checkbox.Root
        checked={checked}
        className={twMerge(
          "flex size-5 items-center justify-center rounded border border-corduroy-300 bg-white outline-none transition-colors duration-200 hover:bg-corduroy-100",
          checked && "border-primary-800",
          className,
        )}
        id={id || idEl}
        {...props}
      >
        <Checkbox.Indicator>
          <Icon className="size-5 text-primary-800" name="check" />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label className="text-sm/none" htmlFor={id || idEl}>
        {children}
      </label>
    </div>
  );
};

export { CheckboxUI as Checkbox };
