import type { Proposal } from "shared/api/proposals";
import type { TableColumn } from "shared/ui/Table";

export const proposalColumns: TableColumn<string, Proposal["messages"][number]["params"]>[] = [
  { key: "unbonding-time", renderTd: (row) => row.unbonding_time, title: "Unbonding Time" },
  { key: "max-validators", renderTd: (row) => row.max_validators, title: "Max Validators" },
  { key: "Max Entries", renderTd: (row) => row.max_entries, title: "Max Entries" },
  {
    key: "Historical Entries",
    renderTd: (row) => row.historical_entries,
    title: "Historical Entries",
  },
  { key: "Bond Denom", renderTd: (row) => row.bond_denom, title: "Bond Denom" },
  {
    key: "Min Commission Rate",
    renderTd: (row) => row.min_commission_rate,
    title: "Min Commission Rate",
  },
];
