import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { LatestBlock, LatestBlockResponse } from "./types";

import { blocksKeys } from "./queryKeys";

type Params = {
  network?: ChainInfo;
};

export const useLatestBlockQuery = (
  { network = nesaTestnet }: Params = {},
  options: UseQueryOptions<LatestBlock> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<LatestBlockResponse>(
        `${network.rest}/cosmos/base/tendermint/v1beta1/blocks/latest`,
        { signal },
      );

      return {
        hash: data.block_id.hash,
        latestBlock: data.block.header.height,
        proposerAddress: data.block.header.proposer_address,
        raw: data,
        time: data.block.header.time,
        transactions: data.block.data.txs,
        validators: data.block.last_commit?.signatures?.length || 0,
      };
    },
    queryKey: blocksKeys.latest({ rest: network.rest }),
    refetchInterval: 30 * 1000, // 30 seconds
    ...options,
  });
};
