import { useEffect } from "react";

import { fromHex, toBase64 } from "@cosmjs/encoding";
import { create } from "zustand";

import { useGetValidatorsQuery } from "shared/api/validators/useGetValidatorsQuery";
import { consensusPubkeyToHexAddress } from "shared/helpers/decode";

type Validator = {
  base64: string;
  hex: string;
  moniker: string;
};
export type ValidatorsCosmos = {
  validators: Validator[];
};

const useValidatorsCosmosStore = create<ValidatorsCosmos>(() => ({
  validators: [],
}));

export const useValidatorsCosmos = () => {
  const { data, status } = useGetValidatorsQuery({
    limit: 500,
    status: "BOND_STATUS_BONDED",
  });

  useEffect(() => {
    if (status === "success") {
      const validators = data?.validators.map((v) => {
        const hex = consensusPubkeyToHexAddress(v.consensus_pubkey);
        return {
          base64: toBase64(fromHex(hex)),
          hex,
          moniker: v.description.moniker,
        };
      });
      useValidatorsCosmosStore.setState({ validators });
    }
  }, [data, status]);
};

export const getValidatorCosmosByAddress = (address: string | undefined) => {
  const { validators } = useValidatorsCosmosStore.getState();

  return validators?.find((el) => el.base64 === address);
};
