import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { DelegationsResponse } from "./types";

import { stakingKeys } from "./queryKeys";

type Params = {
  delegatorAddress: string;
  network?: ChainInfo;
};

export const useStakingDelegationsQuery = (
  { delegatorAddress, network = nesaTestnet }: Params,
  options: UseQueryOptions<DelegationsResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<DelegationsResponse>(
        `${network.rest}/cosmos/staking/v1beta1/delegations/${delegatorAddress}`,
        { signal },
      );

      return data;
    },
    queryKey: stakingKeys.params({ delegatorAddress, rest: network.rest }),
    ...options,
  });
};
