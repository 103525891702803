import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

import { BigNumber } from "bignumber.js";
import { DateTime } from "luxon";

import type { LatestBlock } from "shared/api/blocks/types";

import { TableTransactions } from "features/TableTransactions";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getValidatorCosmosByAddress } from "features/useValidatorsCosmos";
import { useBlockByHeightQuery } from "shared/api/blocks/useBlockByHeightQuery";
import { getChainById } from "shared/helpers/getChainById";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Card } from "shared/ui/Card";
import { Icon } from "shared/ui/Icon";
import { TableTdTitle, TableTdValue, TableTr, TableWrapper } from "shared/ui/ObjectTable";
import { Tabs } from "shared/ui/Tabs";
import coverSrc from "shared/ui/assets/cover.png";

import { HashValue } from "./ui/table-ui";

export const BlockDetails = () => {
  const { blockHeight, chainId } = useParams();
  const navigate = useNavigate();

  const chain = getChainById(chainId);

  const { data: block, status } = useBlockByHeightQuery({
    blockHeight: blockHeight,
    network: chain,
  });

  const { blocksHistory } = useBlocksStore();
  const lastBlock: LatestBlock | undefined = blocksHistory[0];
  const blockHeightNumber = new BigNumber(blockHeight || "");

  if (!blockHeight || blockHeightNumber.isNaN()) {
    return <Navigate replace to={`/${chain.chainId}/blocks`} />;
  }

  if (status === "error") {
    return (
      <AnimateRoute className="relative flex flex-col p-0 pb-5">
        <div className="mx-auto grid w-full max-w-7xl grid-cols-1 gap-5 px-2 sm:px-5">
          <img
            alt=""
            className="absolute left-0 top-0 -z-10 h-44 w-full object-cover"
            src={coverSrc}
          />
          <div className="mb-3 mt-8 text-center">
            <h2 className="h-9 flex-1 text-[1.625rem] font-semibold leading-8 tracking-wide text-white">
              <span className="font-semibold">Error</span>
            </h2>
          </div>
          <Card>
            <Card.Content className="py-10 text-center">
              <div className="mt-3 text-base text-corduroy-900">Something went wrong</div>
            </Card.Content>
          </Card>
        </div>
      </AnimateRoute>
    );
  }

  return (
    <AnimateRoute className="relative flex flex-col p-0 pb-5">
      <div className="mx-auto grid w-full max-w-7xl grid-cols-1 gap-5 px-2 sm:px-5">
        <img
          alt=""
          className="absolute left-0 top-0 -z-10 h-44 w-full object-cover"
          src={coverSrc}
        />
        <div className="mb-3 mt-8 flex items-center gap-2 text-center">
          {status === "success" && (
            <button
              className="inline-flex size-9 cursor-pointer items-center justify-center rounded-full bg-[#0F0E1782] transition-all duration-200 hover:brightness-90 disabled:cursor-not-allowed disabled:opacity-50"
              disabled={blockHeightNumber.isLessThanOrEqualTo(1)}
              onClick={() => navigate(`/${chain.chainId}/blocks/${blockHeightNumber.minus(1)}`)}
            >
              <Icon className="size-5 text-primary-600" name="arrowLongLeft" />
            </button>
          )}
          <h2 className="h-9 flex-1 text-[1.625rem] font-semibold leading-8 tracking-wide text-white">
            <span className="font-light">#</span>
            <span className="font-semibold">{blockHeight}</span>
          </h2>
          {status === "success" && (
            <button
              className="inline-flex size-9 cursor-pointer items-center justify-center rounded-full bg-[#0F0E1782] transition-all duration-200 hover:brightness-90 disabled:cursor-not-allowed disabled:opacity-50"
              disabled={blockHeightNumber.isGreaterThanOrEqualTo(
                new BigNumber(lastBlock?.latestBlock),
              )}
              onClick={() => navigate(`/${chain.chainId}/blocks/${blockHeightNumber.plus(1)}`)}
            >
              <Icon className="size-5 text-primary-600" name="arrowLongRight" />
            </button>
          )}
        </div>

        <Card>
          <Card.Content>
            <TableWrapper>
              <TableTr>
                <TableTdTitle>Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block_id.hash} />
                </TableTdValue>
              </TableTr>
              <TableTr>
                <TableTdTitle>Part Set Header</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <Tabs.Root
                    className="inline-block"
                    defaultValue="total"
                    tabs={
                      <Tabs.List>
                        <Tabs.Trigger className="min-w-20" value="total">
                          Total
                        </Tabs.Trigger>
                        <Tabs.Trigger className="min-w-20" value="hash">
                          Hash
                        </Tabs.Trigger>
                      </Tabs.List>
                    }
                  >
                    <Tabs.Content className="pt-3" value="total">
                      {block?.block_id.part_set_header.total}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="hash">
                      <HashValue hash={block?.block_id.part_set_header.hash} />
                    </Tabs.Content>
                  </Tabs.Root>
                </TableTdValue>
              </TableTr>
            </TableWrapper>
          </Card.Content>
        </Card>

        <Card>
          <Card.BaseTitle className="pb-0">Block Header</Card.BaseTitle>
          <Card.Content className="pt-0">
            <TableWrapper>
              <TableTr>
                <TableTdTitle>Version</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <Tabs.Root
                    className="inline-block"
                    defaultValue="block"
                    tabs={
                      <Tabs.List>
                        <Tabs.Trigger className="min-w-20" value="block">
                          Block
                        </Tabs.Trigger>
                        <Tabs.Trigger className="min-w-20" value="app">
                          App
                        </Tabs.Trigger>
                      </Tabs.List>
                    }
                  >
                    <Tabs.Content className="pt-3" value="block">
                      {block?.block.header.version.block}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="app">
                      {block?.block.header.version.app}
                    </Tabs.Content>
                  </Tabs.Root>
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Chain Id</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>{chain.chainName}</TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Height</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  {block?.block.header.height}
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Time</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  {DateTime.fromISO(block?.block.header.time || "").toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                  )}
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Last Block Id </TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <Tabs.Root
                    className="inline-block"
                    defaultValue="hash"
                    tabs={
                      <Tabs.List>
                        <Tabs.Trigger className="min-w-20" value="hash">
                          Hash
                        </Tabs.Trigger>
                        <Tabs.Trigger value="part_set_header">part_set_header</Tabs.Trigger>
                      </Tabs.List>
                    }
                  >
                    <Tabs.Content className="pt-3" value="hash">
                      <HashValue hash={block?.block.header.last_block_id.hash} />
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="part_set_header">
                      <HashValue hash={block?.block.header.last_block_id.part_set_header.hash} />
                    </Tabs.Content>
                  </Tabs.Root>
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Last Commit Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.last_commit_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Data Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.data_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Validators Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.validators_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Next Validators Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.next_validators_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Consensus Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.consensus_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>App Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.app_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Last Results Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.last_results_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Evidence Hash</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  <HashValue hash={block?.block.header.evidence_hash} />
                </TableTdValue>
              </TableTr>

              <TableTr>
                <TableTdTitle>Proposer Address</TableTdTitle>
                <TableTdValue isLoading={status === "pending"}>
                  {getValidatorCosmosByAddress(block?.block.header.proposer_address)?.moniker ||
                    block?.block.header.proposer_address}
                </TableTdValue>
              </TableTr>
            </TableWrapper>
          </Card.Content>
        </Card>

        <Card>
          <Card.BaseTitle className="flex items-center justify-between ">
            Transactions
            <Link
              className="text-sm font-medium text-primary-900 transition-all duration-200 hover:brightness-95"
              to={`/${chainId}/transactions`}
            >
              View all
            </Link>
          </Card.BaseTitle>
          <TableTransactions
            transactionsCount={10}
            txs={block?.block.data.txs.map((tx) => ({ blockHeight, tx })) || []}
          />
        </Card>
      </div>
    </AnimateRoute>
  );
};
