import { useState } from "react";

import { Input } from "shared/ui/Input";
import { Label } from "shared/ui/Label";
import { Select } from "shared/ui/Select";

const broadcastModes = [
  { label: "Sync", value: "BROADCAST_MODE_SYNC" },
  { label: "Async", value: "BROADCAST_MODE_ASYNC" },
  { label: "Block", value: "BROADCAST_MODE_BLOCK" },
];

export const useAdvancedForm = () => {
  const [feesValue, setFeesValue] = useState("2000");
  const [gasValue, setGasValue] = useState("200000");
  const [memoValue, setMemoValue] = useState("nesa");
  const [broadcastModeValue, setBroadcastModeValue] = useState("BROADCAST_MODE_SYNC");

  return {
    broadcastModeValue,
    feesValue,
    gasValue,
    memoValue,
    setBroadcastModeValue,
    setFeesValue,
    setGasValue,
    setMemoValue,
  };
};

type Props = ReturnType<typeof useAdvancedForm>;

export const AdvancedForm = ({
  broadcastModeValue,
  feesValue,
  gasValue,
  memoValue,
  setBroadcastModeValue,
  setFeesValue,
  setGasValue,
  setMemoValue,
}: Props) => {
  return (
    <>
      <Label>
        <div className="mb-1">Fees</div>
        <Input
          endSlot={<div className="text-sm text-corduroy-800">unes</div>}
          onChange={(e) => setFeesValue(e.target.value)}
          type="number"
          value={feesValue}
        />
      </Label>
      <Label>
        <div className="mb-2">Gas</div>
        <Input
          endSlot={<div className="text-sm text-corduroy-800">unes</div>}
          onChange={(e) => setGasValue(e.target.value)}
          type="number"
          value={gasValue}
        />
      </Label>
      <Label>
        <div className="mb-1">Memo</div>
        <Input onChange={(e) => setMemoValue(e.target.value)} value={memoValue} />
      </Label>

      <Label>
        <div className="mb-1">Broadcast Mode</div>
        <Select onValueChange={setBroadcastModeValue} value={broadcastModeValue}>
          <Select.Content>
            {broadcastModes.map((el) => (
              <Select.Item key={el.value} value={el.value}>
                {el.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Label>
    </>
  );
};
