import type { PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

import type { ClassName } from "shared/types";

export const EmptyText = ({ children, className }: ClassName & PropsWithChildren) => {
  return (
    <p
      className={twMerge(
        "flex h-full items-center justify-center text-center text-sm text-corduroy-400",
        className,
      )}
    >
      {children}
    </p>
  );
};
