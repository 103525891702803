import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { ValidatorResponse } from "./types";

import { validatorsKeys } from "./queryKeys";

type Params = {
  limit: number;
  network?: ChainInfo;
  status: "BOND_STATUS_BONDED" | "BOND_STATUS_UNBONDED" | "BOND_STATUS_UNBONDING";
};

export const useGetValidatorsQuery = (
  { limit, network = nesaTestnet, status }: Params,
  options: UseQueryOptions<ValidatorResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<ValidatorResponse>(
        `${network.rest}/cosmos/staking/v1beta1/validators`,
        {
          params: { limit, status },
          signal,
        },
      );

      return data;
    },
    queryKey: validatorsKeys.list({ limit, status, url: network.rest }),
    ...options,
  });
};
