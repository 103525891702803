import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import type { UseMutationOptions } from "shared/types";

export type SimulateRequest = {
  mode: string;
  tx_bytes: string;
};

export type SimulateResponse = {
  gas_info: {
    gas_used: string;
    gas_wanted: string;
  };
  result: {
    data: string;
    events: {
      attributes: {
        index: boolean;
        key: string;
        value: string;
      }[];
      type: string;
    }[];
    log: string;
    msg_responses: [
      {
        "@type": string;
      },
    ];
  };
};

export const useSimulateMutation = (
  options: UseMutationOptions<SimulateResponse, unknown, SimulateRequest> = {},
) => {
  return useMutation({
    mutationFn: async (payload) => {
      const { data } = await axios.post<SimulateResponse>(
        `https://lcd.test.nesa.ai/cosmos/tx/v1beta1/simulate`,
        payload,
      );

      return data;
    },
    ...options,
  });
};
