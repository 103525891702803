import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { fromBase64 } from "@cosmjs/encoding";
import { decodeTxRaw } from "@cosmjs/proto-signing";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { hashTx } from "shared/helpers/decode";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Icon } from "shared/ui/Icon";
import { Table } from "shared/ui/Table";

type Props = {
  transactionsCount?: number;
  txs: { blockHeight?: string; tx: TransactionQueryResponse | string }[];
};

type TransactionColumn = {
  blockHeight?: string;
  fee: string;
  granter: string;
  hash: string;
  message: string;
  payer: string;
  timestamp: string;
};

const getShortenedHash = (address: string) => {
  return address.slice(0, 24) + "..." + address.slice(-8);
};

export const DashboardTableTransactions = ({ transactionsCount, txs }: Props) => {
  const { chainId } = useParams();

  const chain = getChainById(chainId);

  const sm = useMinWidthMediaQuery("sm");

  const transactions = useMemo(() => {
    const transactions = transactionsCount ? txs.slice(0, transactionsCount) : txs;
    return transactions.map(({ blockHeight, tx }): TransactionColumn => {
      const decoded = typeof tx === "string" ? decodeTxRaw(fromBase64(tx)) : null;
      const body = typeof tx !== "string" ? tx.tx.body : null;

      const hash = typeof tx === "string" ? hashTx(fromBase64(tx)) : tx.txhash;

      const timestamp = "";

      const fee = !decoded ? null : decoded?.authInfo.fee;
      const amount = !fee ? null : fee.amount?.[0];
      const payer = !fee ? null : fee.payer;
      const granter = !fee ? null : fee.granter;

      return {
        blockHeight,
        fee: amount ? getFormattedNumber(amount.amount) + " " + amount.denom : "-",
        granter: granter || "-",
        hash: hash,
        message: getTxMessage(
          (decoded?.body.messages || body?.messages) as TxResponse["tx"]["body"]["messages"],
        ),
        payer: payer || "-",
        timestamp,
      };
    });
  }, [txs, transactionsCount]);

  const transactionsColumns = useMemo(() => {
    const columns: TableColumn<keyof TransactionColumn, TransactionColumn>[] = [
      {
        key: "blockHeight",
        renderTd: (row) => (
          <Link
            className="flex w-44 cursor-pointer items-center gap-3 border-r border-clay-850 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
            to={`/${chain.chainId}/transactions/${row.hash}`}
          >
            {sm && (
              <Icon
                className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300"
                name="arrowLeftRight"
              />
            )}
            <div className="flex flex-col">
              {row.message}
              <span className="text-sm text-clay-500">19 secs ago</span>
            </div>
          </Link>
        ),
        tdClassName: "w-48",
      },
      {
        key: "hash",
        renderTd: (row) => (
          <div className="ml-2 truncate text-sm text-clay-500">
            <span className="mr-3 text-white">Hash</span>
            {row.hash}
          </div>
        ),
      },
      {
        key: "fee",
        renderTd: (row) => {
          const amount = row.fee;
          if (!amount) return null;
          return (
            <div>
              <div className="flex items-center text-sm text-white">
                {sm && <Icon className="mr-2 size-8 p-2 text-tusk-100" name="logo" />}
                {row.fee}
              </div>
            </div>
          );
        },
        tdClassName: "w-36",
      },
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain]);

  return (
    <>
      <div className="w-full overflow-x-auto">
        <Table
          className="w-full table-fixed"
          columns={transactionsColumns}
          data={transactions || []}
          hovered
        />
      </div>
    </>
  );
};
