export const blocksKeys = {
  base: ["blocks"],

  byHeight: (params: Record<string, number | string | undefined>) => [
    ...blocksKeys.base,
    "byHeight",
    params,
  ],

  latest: (params: Record<string, number | string | undefined>) => [
    ...blocksKeys.base,
    "latest",
    params,
  ],
};
