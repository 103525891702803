import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";
import { axiosNodeInstance } from "shared/utils/axiosInstance";

import { explorerAccountsKeys } from "./queryKeys";

type Params = {
  network?: ChainInfo;
};

export const useTotalAccountsQuery = (
  { network = nesaTestnet }: Params = {},
  options: UseQueryOptions<null | string> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const urlToFetch = `${network.rest}/cosmos/auth/v1beta1/accounts?pagination.count_total=true&pagination.limit=1`;
      const { data } = await axiosNodeInstance.get<{
        total: null | string;
      }>("/explorer-accounts/total", {
        params: {
          url: urlToFetch,
        },
        signal,
      });

      return data.total;
    },
    queryKey: explorerAccountsKeys.total({ rest: network.rest, type: "total_accounts" }),
    ...options,
  });
};
