import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { toBase64 } from "@cosmjs/encoding";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { useAccount } from "graz";
import { twJoin } from "tailwind-merge";

import type { TransactionMutateResponse } from "shared/api/transactions/useTransactionMutation";

import { useAccountInfoQuery } from "shared/api/auth/useAccountInfoQuery";
import { useBalancesQuery } from "shared/api/balances";
import { useSimulateMutation } from "shared/api/simulate";
import { useStakingParamsQuery } from "shared/api/staking";
import { useTransactionMutation } from "shared/api/transactions/useTransactionMutation";
import { getChainById } from "shared/helpers/getChainById";
import { type Transaction, getTxBytes } from "shared/helpers/getTxBytes";
import { sendTransactions } from "shared/helpers/sendTransaction";
import { Button } from "shared/ui/Button";
import { Checkbox } from "shared/ui/Checkbox";
import { Modal } from "shared/ui/Modal";
import { Spinner } from "shared/ui/Spinner";

import { getTxMessageValue, validationForm } from "./helpers";
import { AdvancedForm, useAdvancedForm } from "./ui/AdvancedForm";
import { DelegateForm } from "./ui/DelegateForm";
import { VoteForm } from "./ui/VoteForm";

type Props = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  proposalId?: string;
  sender: string;
  title: string;
  type: "delegate" | "vote";
};

export const TxDialog = ({ isOpen, onOpenChange, proposalId, sender, title, type }: Props) => {
  const { chainId } = useParams<{ chainId: string }>();
  const network = getChainById(chainId || "");

  const account = useAccount();

  const [advancedMode, setAdvancedMode] = useState(false);
  const advancedForm = useAdvancedForm();

  // base
  const [senderValue, setSenderValue] = useState(sender);

  // delegate
  const [amountValue, setAmountValue] = useState("");
  const [validator, setValidatorValue] = useState("");

  // vote
  const [voteValue, setVoteValue] = useState("");

  const [isSending, setIsSending] = useState(false);
  const [sendingProgress, setIsSendingProgress] = useState(0);
  const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>();
  const [sendedTransaction, setSendedTransaction] = useState<TransactionMutateResponse>();

  const { data: balances, status: balancesStatus } = useBalancesQuery(
    {
      address: sender,
      network,
    },
    { enabled: Boolean(sender && isOpen) },
  );

  const { data: params, status: paramsStatus } = useStakingParamsQuery(
    { network },
    { enabled: isOpen },
  );

  const { data: accountInfo, status: accountInfoStatus } = useAccountInfoQuery(
    {
      address: account.data?.bech32Address || "",
    },
    { enabled: Boolean(account.data?.bech32Address) },
  );

  const { mutateAsync: simulateRequest } = useSimulateMutation();

  const { mutateAsync: transactionRequest } = useTransactionMutation();

  const isLoading =
    paramsStatus === "pending" || balancesStatus === "pending" || accountInfoStatus === "pending";

  const balance = balances?.balances.find((el) => el.denom === params?.params.bond_denom);

  const submit = async () => {
    try {
      const { errorMessage, isValid } = validationForm({
        advancedForm,
        amountValue,
        balance,
        proposalId,
        senderValue,
        type,
        validator,
        voteValue,
      });

      if (!isValid || !accountInfo || !params) {
        toast.error(errorMessage);
        return;
      }
      setIsSending(true);

      const tx: Transaction = {
        chainId: network.chainId,
        fee: {
          amount: [{ amount: advancedForm.feesValue, denom: params.params.bond_denom || "" }],
          gas: "400000",
        },
        memo: advancedForm.memoValue,
        messages: [
          getTxMessageValue({
            amountValue,
            params,
            proposalId,
            senderValue,
            type,
            validator,
            voteValue,
          }),
        ],
        signerAddress: senderValue,
        signerData: {
          accountNumber: Number(accountInfo.account.account_number),
          chainId: network.chainId,
          sequence: Number(accountInfo.account.sequence),
        },
      };
      const txBytes = getTxBytes({ transaction: tx });

      if (advancedMode) {
        tx.fee.gas = advancedForm.gasValue.toString();
      } else {
        const res = await simulateRequest({
          mode: advancedForm.broadcastModeValue,
          tx_bytes: txBytes,
        });
        tx.fee.gas = new BigNumber(res.gas_info.gas_used).multipliedBy(1.25).toFixed(0);
      }

      setIsSendingProgress(50);
      const txRaw = await sendTransactions(tx);
      setIsSendingProgress(75);

      const newTxBytes = TxRaw.encode(txRaw).finish();
      const txString = toBase64(newTxBytes);

      const { tx_response } = await transactionRequest({
        mode: advancedForm.broadcastModeValue,
        tx_bytes: txString,
      });
      setIsSendingProgress(100);

      setSendedTransaction(tx_response);
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      if (axios.isAxiosError(error)) {
        setTransactionErrorMessage(error.response?.data.message || error.message);
        return;
      }
      if (error instanceof Error) {
        setTransactionErrorMessage(error.message);
        return;
      }
      setTransactionErrorMessage("An unknown error occurred");
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.Title>{title}</Modal.Title>

        {isLoading && (
          <div className="flex justify-center py-5">
            <Spinner className="size-10" />
          </div>
        )}

        {!sendedTransaction && !isSending && !isLoading && account.isConnected && (
          <>
            <div className="grid grid-cols-1 gap-2">
              {type === "delegate" && balances && params && (
                <DelegateForm
                  amountValue={amountValue}
                  balances={balances?.balances}
                  params={params}
                  senderValue={senderValue}
                  setAmountValue={setAmountValue}
                  setSenderValue={setSenderValue}
                  setValidatorValue={setValidatorValue}
                  validator={validator}
                />
              )}

              {type === "vote" && balances && params && (
                <VoteForm
                  senderValue={senderValue}
                  setSenderValue={setSenderValue}
                  setVoteValue={setVoteValue}
                  voteValue={voteValue}
                />
              )}

              {advancedMode && <AdvancedForm {...advancedForm} />}
            </div>
            {transactionErrorMessage && (
              <div className="mt-5 rounded-lg bg-red-800/10 p-2 text-center text-xs text-red-800">
                {transactionErrorMessage}
              </div>
            )}
            <footer className="mt-5 flex justify-between">
              <Checkbox checked={advancedMode} onCheckedChange={(el) => setAdvancedMode(!!el)}>
                Advanced
              </Checkbox>
              <Button className="min-w-28" onClick={submit}>
                {type === "vote" && "Vote"}
                {type === "delegate" && "Delegate"}
              </Button>
            </footer>
          </>
        )}
        {!isLoading && !account.isConnected && (
          <div className="py-5 text-center text-base font-semibold text-corduroy-900">
            No wallet connected!
          </div>
        )}

        {(isSending || sendedTransaction) && (
          <div className="py-5 text-center">
            <div
              className={twJoin(
                "mb-5 text-lg font-semibold",
                sendedTransaction ? "text-primary-1000" : "text-corduroy-600",
              )}
            >
              {isSending ? `Proccessing...` : `Transaction completed`}
            </div>
            <div className="relative h-8 w-full overflow-hidden rounded-md bg-corduroy-200">
              <div
                className="absolute left-0 top-0 h-full bg-primary-800"
                style={{ width: `${sendingProgress}%` }}
              />
              <div className="relative text-sm font-medium leading-8 text-white">
                {sendingProgress}%
              </div>
            </div>
            {sendedTransaction && (
              <Link
                className="mt-5 block cursor-pointer font-medium text-corduroy-600 duration-200 hover:text-primary-1000"
                to={`/${chainId}/transactions/${sendedTransaction.txhash}`}
              >
                View transaction
              </Link>
            )}
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
