export const getFormattedNumber = (value?: number | string) => {
  const numValue = Number(value);
  if (!value || Number.isNaN(numValue)) return "";

  return numValue.toLocaleString("en-US");
};

export function decimal2percent(v?: string) {
  return v ? parseFloat((Number(v) * 100).toFixed(2)) : "";
}
