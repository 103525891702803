import type { ComponentPropsWithoutRef } from "react";

import { twMerge } from "tailwind-merge";

import { useEvent } from "shared/hooks/useEvent";

import type { TableProps, TableRowExt, TableSort } from "./types";

import { Th, TrTd } from "./ui";

export const Table = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  className,
  columns,
  data,
  hovered,
  keyTrFormatter,
  onRowClick,
  onSortChange,
  sort,
}: TableProps<ColumnKey, Row>) => {
  const onSort = useEvent((sort: TableSort<ColumnKey>) => {
    onSortChange?.(sort);
  });
  const onRowClickMemo = useEvent((row: Row) => {
    onRowClick?.(row);
  });

  const hasTh = columns.some((el) => !!el.title);

  return (
    <TableUI className={className}>
      {hasTh && (
        <thead>
          <tr>
            {columns.map((column) => (
              <Th column={column} key={column.key} onSortChange={onSort} sort={sort} />
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((row, i) => (
          <TrTd
            columns={columns}
            hasTh={hasTh}
            hovered={hovered}
            key={keyTrFormatter?.(row, i) || i}
            onRowClick={onRowClick ? onRowClickMemo : undefined}
            row={row}
          />
        ))}
      </tbody>
    </TableUI>
  );
};

export const TableUI = (props: ComponentPropsWithoutRef<"table">) => {
  return (
    <table
      {...props}
      className={twMerge(
        "relative w-full border-separate border-spacing-x-0 border-spacing-y-1",
        props.className,
      )}
    />
  );
};
