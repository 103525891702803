export const getFormattedNumberWithUnit = (value?: number | string) => {
  const numValue = Number(value);
  if (!value || Number.isNaN(numValue)) return 0;

  const suffixes = ["", "K", "M", "B", "T", "P", "E"];

  if (Math.abs(numValue) < 1000) {
    return numValue.toLocaleString("en-US");
  }

  let suffixIndex = 0;
  let scaledValue = numValue;

  while (Math.abs(scaledValue) >= 1000 && suffixIndex < suffixes.length - 1) {
    scaledValue /= 1000;
    suffixIndex++;
  }

  const formattedNumber = Number.isInteger(scaledValue)
    ? scaledValue.toLocaleString("en-US")
    : scaledValue.toFixed(2);

  return `${formattedNumber}${suffixes[suffixIndex]}`;
};
