import type { ChainInfo } from "@keplr-wallet/types";

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseMutationOptions, UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { LatestBlock, LatestBlockResponse } from "./types";

import { blocksKeys } from "./queryKeys";

type BlockByHeightMutationParams = {
  network?: ChainInfo;
};
export const useBlockByHeightMutation = (
  { network = nesaTestnet }: BlockByHeightMutationParams = {},
  options: UseMutationOptions<LatestBlock, unknown, string> = {},
) => {
  return useMutation({
    mutationFn: async (height) => {
      const { data } = await axios.get<LatestBlockResponse>(
        `${network.rest}/cosmos/base/tendermint/v1beta1/blocks/${height}`,
      );

      return {
        hash: data.block_id.hash,
        latestBlock: data.block.header.height,
        proposerAddress: data.block.header.proposer_address,
        raw: data,
        time: data.block.header.time,
        transactions: data.block.data.txs,
        validators: data.block.last_commit?.signatures?.length || 0,
      };
    },
    ...options,
  });
};

type BlockByHeightQueryParams = {
  blockHeight?: string;
  network?: ChainInfo;
};

export const useBlockByHeightQuery = (
  { blockHeight, network = nesaTestnet }: BlockByHeightQueryParams = {},
  options: UseQueryOptions<LatestBlockResponse> = {},
) => {
  return useQuery({
    enabled: !!blockHeight,
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<LatestBlockResponse>(
        `${network.rest}/cosmos/base/tendermint/v1beta1/blocks/${blockHeight}`,
        { signal },
      );

      return data;
    },
    queryKey: blocksKeys.byHeight({ blockHeight, rest: network.rest }),
    ...options,
  });
};
