import type { ChainInfo } from "@keplr-wallet/types";

import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";

import { nesaTestnet } from "app/config/chains";

export interface NetworkProps {
  network: ChainInfo;
  onChange: (network: ChainInfo) => void;
}

export const NetworkContext = createContext<NetworkProps>({
  network: nesaTestnet,
  onChange: () => {},
});

export const NetworkContextProvider = ({ children }: PropsWithChildren) => {
  const [network, setNetwork] = useState<ChainInfo>(nesaTestnet);

  return (
    <NetworkContext.Provider value={{ network, onChange: setNetwork }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetwork = () => useContext(NetworkContext);
