import type { ComponentProps, PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

export const Label = ({
  children,
  className,
  ...props
}: PropsWithChildren<ComponentProps<"label">>) => {
  return (
    <label
      className={twMerge("mb-1.5 text-sm/none font-normal  2xl:text-sm/none", className)}
      {...props}
    >
      {children}
    </label>
  );
};
