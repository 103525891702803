import { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BigNumber } from "bignumber.js";
import { useAccount } from "graz";
import { twMerge } from "tailwind-merge";

import type { Delegation } from "shared/api/staking/types";
import type { TableColumn } from "shared/ui/Table";

import { TxDialog } from "features/TxDialog";
import { WalletProviderModal } from "features/WalletProviderModal";
import { useAccountInfoQuery } from "shared/api/auth/useAccountInfoQuery";
import { useBalancesQuery } from "shared/api/balances";
import { useStakingDelegationsQuery } from "shared/api/staking/useStakingDelegationsQuery";
import { useAccountTxsQuery } from "shared/api/transactions/useAccountTxsQuery";
import { fromUnesToNes } from "shared/helpers/fromUnesToNes";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Button } from "shared/ui/Button";
import { Card } from "shared/ui/Card";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";

import { useTxsColumns } from "./hooks/useTxsColumns";

const rowStyles = "grid grid-cols-6 border-b border-b-corduroy-100 py-3";

export const Address = () => {
  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isDelegateOpen, setIsDelegateOpen] = useState(false);

  const { address, chainId } = useParams<{ address: string; chainId: string }>();

  const account = useAccount();

  const network = getChainById(chainId);

  useEffect(() => {
    if (!address) {
      redirect("/");
    }
  }, [address]);

  const { data, isPending: isTxsPending } = useAccountTxsQuery(
    { address: address!, limit: 30, orderBy: "ORDER_BY_DESC" },
    { enabled: !!address },
  );

  const { data: accountInfoData, isPending: isAccountInfoPending } = useAccountInfoQuery(
    { address: address! },
    { enabled: !!address },
  );

  const { data: txs, isPending: isTxsLoading } = useAccountTxsQuery(
    {
      address: address!,
      limit: 20,
      orderBy: "ORDER_BY_DESC",
      query: `message.sender='${address}'`,
    },
    { enabled: !!address },
  );

  const { data: delegations, isPending: isDelegationsPending } = useStakingDelegationsQuery(
    { delegatorAddress: address!, network },
    { enabled: !!address },
  );

  const { data: balances, isPending: isBalancesPending } = useBalancesQuery({
    address: address,
    network,
  });

  const sumBalances = useMemo(() => {
    const sum = balances?.balances.reduce<BigNumber>((acc, x) => {
      acc = acc.plus(x.denom === "unes" ? fromUnesToNes(x.amount) : x.amount);
      return acc;
    }, new BigNumber(0));
    return getFormattedNumber(sum?.toString()) || "-";
  }, [balances]);

  const accountInfo = accountInfoData?.account;

  const isPending =
    isTxsPending ||
    isAccountInfoPending ||
    isBalancesPending ||
    isDelegationsPending ||
    isTxsLoading;

  const txColumns = useTxsColumns();

  const delegationColumns: TableColumn<string, Delegation>[] = [
    {
      key: "validator",
      renderTd: ({ delegation: { validator_address } }) => {
        return <div>{validator_address}</div>;
      },
      title: "Validator",
    },
    {
      key: "delegation",
      renderTd: ({ balance }) => {
        return (
          <div>
            {balance.amount} {balance.denom}
          </div>
        );
      },
      title: "Delegation",
    },
    {
      key: "rewards",
      renderTd: () => {
        return <div>-</div>;
      },
      title: "Rewards",
    },
    {
      key: "action",
      renderTd: () => {
        return <div>-</div>;
      },
      title: "Action",
    },
  ];

  return (
    <AnimateRoute className="p-6">
      <h2 className="mb-6 text-2xl font-semibold">Account</h2>

      {isPending ? (
        <div className="flex flex-col items-center justify-center py-8">
          <Spinner className="size-6" />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <Card>
            <Card.Content>
              <h1 className="mb-3 text-lg font-medium">Account</h1>

              <div className="flex flex-col">
                <div className={rowStyles}>
                  <div className="text-corduroy-500">Type</div>
                  <div className="col-span-5">{accountInfo?.["@type"]}</div>
                </div>
                <div className={rowStyles}>
                  <div className="text-corduroy-500">Account number</div>
                  <div className="col-span-5">{accountInfo?.account_number}</div>
                </div>
                <div className={rowStyles}>
                  <div className="text-corduroy-500">Address</div>
                  <div className="col-span-5 flex items-center gap-3">
                    <span>{accountInfo?.address}</span>
                    <CopyToClipboard
                      onCopy={() => toast.success("Copied")}
                      text={accountInfo?.address || ""}
                    >
                      <Icon
                        className="cursor-pointer text-primary-800 duration-200 hover:text-primary-1000"
                        name="copy"
                      />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className={rowStyles}>
                  <div className="text-corduroy-500">Pubkey Type</div>
                  <div className="col-span-5">{accountInfo?.pub_key?.["@type"]}</div>
                </div>
                <div className={rowStyles}>
                  <div className="text-corduroy-500">Pubkey Key</div>
                  <div className="col-span-5">{accountInfo?.pub_key?.key}</div>
                </div>
                <div className={rowStyles}>
                  <div className="text-corduroy-500">Sequence</div>
                  <div className="col-span-5">{accountInfo?.sequence}</div>
                </div>
                <div className={twMerge(rowStyles, "border-b-0")}>
                  <div className="text-corduroy-500">Balance</div>
                  <div className="col-span-5">{sumBalances} NES</div>
                </div>
              </div>
            </Card.Content>
          </Card>

          {/* <div className="rounded-lg bg-white p-3 shadow-md">
            <h4 className="mb-5 text-lg font-semibold">Assets</h4>
            <div className="flex">
              <div className="size-80">
                <PieChart
                  data={[
                    { name: "Balance", value: sumBalances || 0 },
                    { name: "Delegation", value: sumDelegations || 0 },
                    { name: "Reward", value: rewardsBalances || 0 },
                    { name: "Unbonding", value: unbondingBalances || 0 },
                  ]}
                />
              </div>
              <div className="flex-1"></div>
            </div>
          </div> */}
          <div className="rounded-lg bg-white shadow-md">
            <div className="mb-2 flex items-center justify-between px-3 pb-1 pt-3">
              <h4 className="text-lg font-semibold">Delegations</h4>
              <Button
                onClick={() => {
                  if (account.isConnected) {
                    setIsDelegateOpen(true);
                  } else {
                    setIsProviderOpen(true);
                  }
                }}
              >
                Delegate
              </Button>
            </div>
            {isDelegationsPending ? (
              <div className="flex items-center justify-center pb-8 pt-4">
                <Spinner className="size-6" />
              </div>
            ) : (delegations?.delegation_responses || []).length > 0 ? (
              <div className="overflow-x-auto">
                <Table
                  className="min-w-min"
                  columns={delegationColumns}
                  data={delegations?.delegation_responses || []}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center pb-6 pt-2 text-center text-corduroy-600">
                No delegations
              </div>
            )}
          </div>

          <div className="rounded-lg bg-white shadow-md">
            <h4 className="mb-2 px-3 pb-1 pt-3 text-lg font-semibold">Transactions</h4>
            {isTxsLoading ? (
              <div className="flex items-center justify-center pb-8 pt-4">
                <Spinner className="size-6" />
              </div>
            ) : (
              <div className="overflow-x-auto">
                <Table className="min-w-min" columns={txColumns} data={txs?.tx_responses || []} />
              </div>
            )}
          </div>

          <div className="rounded-lg bg-white shadow-md">
            <h4 className="mb-2 px-3 pb-1 pt-3 text-lg font-semibold">Recent Received</h4>
            {isTxsPending ? (
              <div className="flex items-center justify-center pb-8 pt-4">
                <Spinner className="size-6" />
              </div>
            ) : (data?.tx_responses || []).length > 0 ? (
              <div className="overflow-x-auto">
                <Table className="min-w-min" columns={txColumns} data={data?.tx_responses || []} />
              </div>
            ) : (
              <div className="flex items-center justify-center pb-6 pt-2 text-center text-corduroy-600">
                No data
              </div>
            )}
          </div>
        </div>
      )}

      <WalletProviderModal
        isOpen={isProviderOpen}
        onConnected={() => setIsDelegateOpen(true)}
        onOpenChange={setIsProviderOpen}
      />

      {account.data && (
        <TxDialog
          isOpen={isDelegateOpen}
          onOpenChange={setIsDelegateOpen}
          sender={account.data.bech32Address}
          title="Delegate"
          type="delegate"
        />
      )}
    </AnimateRoute>
  );
};
