import type { Coin } from "@keplr-wallet/types";

import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import { useTransactionByHashQuery } from "shared/api/transactions/useTransactionByHashQuery";
import { base64ToString } from "shared/helpers/base64ToString";
import { timeAgo } from "shared/helpers/timeAgo";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Card } from "shared/ui/Card";
import { HexDecodeText } from "shared/ui/HexDecodeText";
import { Spinner } from "shared/ui/Spinner";
import { Tabs } from "shared/ui/Tabs";

const rowStyles = "grid grid-cols-6 border-b border-b-corduroy-100 py-3";

const getSectionTitleByKey = (key: string) => {
  if (key === "txhash") {
    return "Tx Hash";
  }

  return key.replace(/[@_]/, " ").trim();
};

const checkIsAmountArray = (value: unknown): value is Coin[] => {
  const hasAmount = !!(value as Coin[])?.[0]?.amount;

  return hasAmount;
};

const checkIsAmount = (value: unknown): value is Coin => {
  const hasAmount = !!(value as Coin)?.amount;

  return hasAmount;
};

export const Transaction = () => {
  const navigate = useNavigate();
  const { chainId, hash } = useParams<{ chainId: string; hash: string }>();
  const { data, isPending } = useTransactionByHashQuery({ hash: hash! }, { enabled: !!hash });

  const { tx, tx_response } = data || {};

  const messages =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tx?.body?.messages.map((msg: any) => {
      if (msg.packet?.data) {
        return {
          ...msg,
          message: base64ToString(msg.packet.data),
        };
      }
      return msg;
    }) || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const feeData = (tx as any)?.["auth_info"]?.fee?.amount[0];

  return (
    <AnimateRoute className="p-6">
      <h1 className="mb-6 text-2xl font-semibold">Transaction</h1>

      <Card className="mb-6">
        <Card.Content>
          <h1 className="mb-3 text-lg font-medium">Summary</h1>

          {isPending ? (
            <div className="flex flex-col items-center justify-center py-8">
              <Spinner className="size-6" />
            </div>
          ) : (
            <div className="flex flex-col">
              <div className={rowStyles}>
                <div className="text-corduroy-500">Tx Hash</div>
                <div className="col-span-5">{tx_response?.txhash}</div>
              </div>
              <div className={rowStyles}>
                <div className="text-corduroy-500">Height</div>
                <div className="col-span-5">{tx_response?.height}</div>
              </div>
              <div className={twMerge(rowStyles, "py-2")}>
                <div className="flex items-center text-corduroy-500">Status</div>
                <div
                  className={twMerge(
                    "col-span-5 w-fit rounded-md bg-pink-500/20 px-2 py-1 text-pink-500",
                    tx_response?.code === 0 && "bg-primary-900/20 text-primary-900",
                  )}
                >
                  {tx_response?.code === 0 ? "Success" : "Failed"}
                </div>
              </div>
              <div className={rowStyles}>
                <div className="text-corduroy-500">Time</div>
                <div className="col-span-5">
                  {tx_response?.timestamp
                    ? `${DateTime.fromISO(tx_response.timestamp).toFormat("yyyy-MM-dd hh:mm:ss")} (${timeAgo(DateTime.fromISO(tx_response.timestamp))})`
                    : "-"}
                </div>
              </div>

              <div className={rowStyles}>
                <div className="text-corduroy-500">Gas</div>
                <div className="col-span-5">
                  {tx_response?.gas_used || "-"} / {tx_response?.gas_wanted || "-"}
                </div>
              </div>

              <div className={rowStyles}>
                <div className="text-corduroy-500">Fee</div>
                <div className="col-span-5">
                  {feeData?.amount} {feeData?.denom}
                </div>
              </div>

              <div className={twMerge(rowStyles, "border-b-transparent pb-0")}>
                <div className="text-corduroy-500">Memo</div>
                <div className="col-span-5">{tx?.body.memo || "Not defined"}</div>
              </div>
            </div>
          )}
        </Card.Content>
      </Card>

      {messages.length > 0 && (
        <Card>
          <Card.Content>
            <h1 className="mb-3 text-lg font-medium">Messages ({messages.length})</h1>

            {messages.map((msg, i) => {
              return (
                <div className="flex flex-col" key={i}>
                  <h2 className="pb-1 pt-4 font-medium text-corduroy-600">Message {i + 1}</h2>

                  {Object.entries(msg).map(([key, value]) => {
                    const isStr = typeof value === "string";
                    const isAccount = key === "account";
                    const isAmountArray = checkIsAmountArray(value);
                    const isAmount = checkIsAmount(value);
                    const isVrf = key === "vrf";

                    return (
                      <div className={rowStyles} key={key}>
                        <div className="capitalize text-corduroy-500">
                          {getSectionTitleByKey(key)}
                        </div>
                        {isStr &&
                          (isAccount ? (
                            <div
                              className="col-span-5 cursor-pointer font-medium text-primary-900 transition-colors hover:text-primary-1000"
                              onClick={() => {
                                navigate(`/${chainId}/address/${value}`);
                              }}
                            >
                              {value}
                            </div>
                          ) : (
                            <div className="col-span-5">{value}</div>
                          ))}
                        {isAmountArray && (
                          <div className="col-span-5">
                            {value?.[0].amount}
                            {value?.[0].denom}
                          </div>
                        )}
                        {isAmount && (
                          <div className="col-span-5">
                            {value?.amount}
                            {value?.denom}
                          </div>
                        )}
                        {isVrf && (
                          <div className="col-span-5">
                            <Tabs.Root
                              defaultValue="seed"
                              tabs={
                                <Tabs.List className="mb-4">
                                  <Tabs.Trigger value="seed">Seed</Tabs.Trigger>
                                  <Tabs.Trigger value="proof">Proof</Tabs.Trigger>
                                  <Tabs.Trigger value="hash_random">Hash Random</Tabs.Trigger>
                                </Tabs.List>
                              }
                            >
                              <Tabs.Content className="flex w-full items-center gap-1" value="seed">
                                <HexDecodeText value={msg.vrf.seed} />
                              </Tabs.Content>
                              <Tabs.Content className="w-full" value="proof">
                                {msg.vrf.proof}
                              </Tabs.Content>
                              <Tabs.Content className="w-full" value="hash_random">
                                <HexDecodeText value={msg.vrf.hash_random} />
                              </Tabs.Content>
                            </Tabs.Root>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Card.Content>
        </Card>
      )}
    </AnimateRoute>
  );
};
