import type { PropsWithChildren } from "react";

import { useGlobalLatestBlocks } from "features/useGlobalLatestBlocks";
import { useValidatorsCosmos } from "features/useValidatorsCosmos";

export const Prefetch = ({ children }: PropsWithChildren) => {
  useGlobalLatestBlocks();
  useValidatorsCosmos();

  return children;
};
