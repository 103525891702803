import { useCallback, useEffect, useMemo, useState } from "react";

import { twMerge } from "tailwind-merge";

import type { Block, ValidatorUnit } from "shared/api/blocks/types";

import { theme } from "app/theme";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { Tooltip } from "shared/ui/Tooltip";

import { UptimeBar } from "./UptimeBar";

type Props = {
  validators: ValidatorUnit[];
};

const legends = [
  { color: "bg-primary-900", text: "Committed" },
  { color: "bg-yellow-500", text: "Precommitted" },
  { color: "bg-red-800", text: "Missed" },
];

export const BlocksTab = ({ validators }: Props) => {
  const [blockColors, setBlockColors] = useState<
    Record<string, { color: string; height: string; isPassed: boolean }[]>
  >({});

  const { blocksHistory } = useBlocksStore();

  const latestFilledBlock = useMemo(() => {
    const validator = validators[0].base64;
    const len = blockColors[validator]?.length || 0;

    if (len - 1) return undefined;

    return blockColors[validator][len - 1].height;
  }, [blockColors, validators]);

  const grayBlocks = new Array(50).fill("").map(() => ({
    color: theme.colors.corduroy[400],
    height: "",
  }));

  const fillBlock = useCallback(
    (b: Block) => {
      validators?.forEach((v) => {
        setBlockColors((prev) => {
          const newColors = [...(prev[v.base64] || []), getBlockColorByValidator(v, b)];
          return { ...prev, [v.base64]: newColors.length > 50 ? newColors.slice(1) : newColors };
        });
      });
    },
    [validators],
  );

  useEffect(() => {
    if (latestFilledBlock === blocksHistory[0].latestBlock) return;

    fillBlock(blocksHistory[0].raw);
  }, [blocksHistory, fillBlock, latestFilledBlock]);

  const getBlockColorByValidator = (validator: ValidatorUnit, { block }: Block) => {
    const sig = block.last_commit?.signatures.find((s) => s.validator_address === validator.base64);

    if (sig) {
      return {
        color: sig.block_id_flag === "BLOCK_ID_FLAG_COMMIT" ? "#02BEAC" : "#FF9E0D",
        height: block.header.height,
        isPassed: sig.block_id_flag === "BLOCK_ID_FLAG_COMMIT",
      };
    }

    return {
      color: "#FD5A5A",
      height: block.header.height,
      isPassed: false,
    };
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-wrap justify-center gap-1.5 sm:gap-5 ">
        <span className="font-medium">Legend:</span>

        {legends.map(({ color, text }) => {
          return (
            <span className="flex items-center gap-1.5 font-light" key={text}>
              <div className={twMerge("h-4 w-[4px]", color)} />
              {text}
            </span>
          );
        })}
      </div>

      <div className="flex items-center justify-center text-center text-xs text-corduroy-600">
        Updates occur as blocks are produced
      </div>

      <div className="flex w-full max-w-[400px] flex-col gap-5">
        {validators?.map((unit) => {
          // const hasMissedBlocksCounter = Number(unit?.missed_blocks_counter || 0) > 10;
          const filledBlocks = blockColors?.[unit.base64] || [];
          const missedBlocksCounter = filledBlocks.filter(({ isPassed }) => !isPassed).length;
          const hasMissedBlocksCounter = missedBlocksCounter > 10;
          const filledBlocksCount = filledBlocks.length;
          const latestFilledBlock =
            filledBlocksCount > 0 ? Number(filledBlocks[filledBlocksCount - 1].height) : 0;

          const grayBlocksData = grayBlocks
            .slice(filledBlocksCount)
            .map((item, i) => ({ ...item, height: `${latestFilledBlock + 1 + i}` }));

          return (
            <div
              className="w-full rounded-lg border border-corduroy-100 bg-white p-4 pt-2.5"
              key={`uptime-${unit.moniker}`}
            >
              <div className="mb-1 flex items-center justify-between">
                <div className="ml-1 truncate dark:text-white">{unit.moniker}</div>

                <Tooltip content="Number of precommitted or missed blocks" side="top">
                  <div
                    className={twMerge(
                      "cursor-default bg-transparent font-bold text-primary-900",
                      hasMissedBlocksCounter && "text-red-800",
                    )}
                  >
                    {missedBlocksCounter}
                  </div>
                </Tooltip>
              </div>

              <UptimeBar blocks={[...filledBlocks, ...grayBlocksData]} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
