import { useParams } from "react-router-dom";

import { useSupplyByDenomQuery } from "shared/api/bank/useSupplyByDenomQuery";
import { fromUnesToNes } from "shared/helpers/fromUnesToNes";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Card } from "shared/ui/Card";
import { Spinner } from "shared/ui/Spinner";

export const Supply = () => {
  const { chainId } = useParams<{ chainId: string }>();

  const network = getChainById(chainId || "");

  const { data: supply, isLoading: isSupplyLoading } = useSupplyByDenomQuery({ network });

  return (
    <AnimateRoute className="p-6">
      <Card>
        <Card.Title className="grid grid-cols-3">
          <div>Token</div>
          <div className="col-span-2">Amount</div>
        </Card.Title>

        <Card.Content className="grid grid-cols-3">
          <div>{network.currencies[0].coinMinimalDenom}</div>
          <div className="col-span-2">
            {isSupplyLoading ? (
              <Spinner className="size-3" />
            ) : supply?.amount?.amount ? (
              getFormattedNumber(fromUnesToNes(supply.amount.amount).toNumber())
            ) : (
              "-"
            )}
          </div>
        </Card.Content>
      </Card>
    </AnimateRoute>
  );
};
