import type { TxResponse } from "shared/api/transactions/types";

export const getTxMessage = (messages: TxResponse["tx"]["body"]["messages"]) => {
  return messages
    .map((msg) => {
      const msgType = ((msg as { ["@type"]: string })["@type"] ||
        ("typeUrl" in msg && msg.typeUrl) ||
        "unknown") as string;

      return msgType.substring(msgType.lastIndexOf(".") + 1).replace("Msg", "");
    })
    .join(",");
};
