// /cosmos/tx/v1beta1/txs/9EDD95C240E4682011CCFDC543DE490011A9971A170E86D0B914358183FAFF12

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { Network } from "shared/helpers/getBaseEndpoints";
import type { UseQueryOptions } from "shared/types";

import { getBaseEndpoints } from "shared/helpers/getBaseEndpoints";

import type { GetAccountTxListResponse } from "./types";

import { transactionKeys } from "./queryKeys";
// https://cosmos-rest.tpblock.io/cosmos/tx/v1beta1/txs?pagination.reverse=true&query=coin_received.receiver=%27nesa1d4atnwcvkgcgfza65uc0p67j77d3pkyqaq563v%27&order_by=ORDER_BY_DESC&pagination.limit=2
// https://cosmos-rest.tpblock.io/cosmos/tx/v1beta1/txs?query=coin_received.receiver=%27nesa1d4atnwcvkgcgfza65uc0p67j77d3pkyqaq563v%27&pagination.count_total=true
type Params = {
  address: string;
  limit: number;
  network?: Network;
  orderBy?: "ORDER_BY_ASC" | "ORDER_BY_DESC" | "ORDER_BY_UNSPECIFIED";
  page?: number;
  query?: string;
  // events
};

// /cosmos/tx/v1beta1/txs?&pagination.reverse=true&events=coin_received.receiver=%27osmo1fhn5g5u74jslqgvknj32gf5pmxvcumk46h90g5%27&pagination.limit=5&pagination.limit=20&pagination.count_total=true
// https://lcd.test.nesa.ai/cosmos/tx/v1beta1/txs?limit=20&order_by=ORDER_BY_DESC&query=message.action%3D%27%2Fagent.v1.MsgRegisterSession%27+AND+tx.height%3E478026
export const useAccountTxsQuery = (
  { address, limit, network = "testnet", orderBy = "ORDER_BY_DESC", query, ...params }: Params,
  options: UseQueryOptions<GetAccountTxListResponse> = {},
) => {
  const baseUrl = getBaseEndpoints(network);
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<GetAccountTxListResponse>(
        `${baseUrl}/cosmos/tx/v1beta1/txs`,
        {
          params: {
            ...params,
            limit,
            order_by: orderBy,
            query: query || `coin_received.receiver='${address}'`,
          },
          signal,
        },
      );

      return data;
    },
    queryKey: transactionKeys.item({
      address,
      baseUrl,
      limit,
      orderBy,
      query,
      ...params,
    }),
    ...options,
  });
};
