import type { ChainInfo } from "@keplr-wallet/types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";

import type { SigningInfoResponse } from "./types";

import { slashingKeys } from "./queryKeys";

type Params = {
  limit: number;
  network?: ChainInfo;
};

export const useGetSlashingSigningInfoQuery = (
  { limit, network = nesaTestnet }: Params,
  options: UseQueryOptions<SigningInfoResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<SigningInfoResponse>(
        `${network.rest}/cosmos/slashing/v1beta1/signing_infos`,
        {
          params: { limit },
          signal,
        },
      );

      return data;
    },
    queryKey: slashingKeys.list({ limit, url: network.rest }),
    ...options,
  });
};
