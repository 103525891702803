import type { PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

import type { ClassName } from "shared/types";

type Props = ClassName;

const CardTitle = ({ children, className }: PropsWithChildren<ClassName>) => {
  return (
    <div className={twMerge("border-b border-corduroy-50 bg-corduroy-100 px-4 py-3", className)}>
      {children}
    </div>
  );
};

const CardBaseTitle = ({ children, className }: PropsWithChildren<ClassName>) => {
  return (
    <div className={twMerge("p-5 text-base font-semibold text-corduroy-800", className)}>
      {children}
    </div>
  );
};

const CardContent = ({ children, className }: PropsWithChildren<ClassName>) => {
  return <div className={twMerge("flex flex-col p-4", className)}>{children}</div>;
};

export const Card = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <div
      className={twMerge(
        "flex w-full flex-col overflow-hidden rounded-lg bg-white shadow-md",
        className,
      )}
    >
      {children}
    </div>
  );
};

Card.Title = CardTitle;
Card.Content = CardContent;
Card.BaseTitle = CardBaseTitle;
