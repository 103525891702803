import type { ComponentPropsWithoutRef } from "react";

import { twMerge } from "tailwind-merge";

import type { TableColumn, TableRowExt } from "../types";

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  column: TableColumn<ColumnKey, Row>;
  hasTh?: boolean;
  hovered?: boolean;
  isClicked?: boolean;
  row: Row;
};

export const TdSmall = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  column,
  hasTh,
  hovered,
  isClicked,
  row,
}: Props<ColumnKey, Row>) => {
  return (
    <TdUISmall
      className={twMerge(
        (isClicked || hovered) && "group-hover:bg-corduroy-50",
        !hasTh && "group-first:border-t-0",
        column.tdClassName,
      )}
    >
      {column.renderTd(row)}
    </TdUISmall>
  );
};

export const TdUISmall = (props: ComponentPropsWithoutRef<"td">) => {
  return (
    <td
      {...props}
      className={twMerge(
        "border-t border-[#EEEFF3] bg-white px-2 py-1 text-xs font-normal text-corduroy-900 group-last:border-b",
        props.className,
      )}
    />
  );
};
