import type { ChainInfo } from "@keplr-wallet/types";

import { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAccount } from "graz";
import { twMerge } from "tailwind-merge";

import type { IconName } from "shared/ui/Icon";

import logoSrc from "app/assets/images/logo-name.svg";
import { WalletModal } from "features/WalletModal/WalletModal";
import { WalletProviderModal } from "features/WalletProviderModal";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getShortenedAddress } from "shared/helpers/getShortenedAddress";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Button } from "shared/ui/Button";
import { Icon } from "shared/ui/Icon";
import { Input } from "shared/ui/Input";

const height = /^\d+$/;
const txhash = /^[A-Z\d]{64}$/;
const addr = /^[a-z\d]+1[a-z\d]{38,58}$/;

type MenuItem = {
  icon?: IconName;
  label: string;
  link: string;
};

type DataItem = {
  data: string;
  icon: IconName;
  label: string;
};

type LinkItem = {
  label: string;
  link: string;
};

const getMenuItemsNesa = (chain: ChainInfo): MenuItem[] => [
  { label: "Dashboard", link: `/${chain.chainId}/dashboard` },
  { label: "Transactions", link: `/${chain.chainId}/transactions` },
  { label: "Blocks", link: `/${chain.chainId}/blocks` },
  { label: "Governance", link: `/${chain.chainId}/governance` },
  { label: "Uptime", link: `/${chain.chainId}/uptime` },
  { label: "Supply", link: `/${chain.chainId}/supply` },
];

const menuItemsEcosystem: MenuItem[] = [
  { icon: "star", label: "Favorites", link: "/favorite" },
  { label: "All Blockchains", link: "/blockchains" },
];

const linkItems: LinkItem[] = [
  { label: "Visit Nesa.ai", link: "https://nesa.ai/" },
  { label: "Nesa Playground", link: "https://beta.nesa.ai/" },
];

export const Header = () => {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const { data: account, isConnected } = useAccount();

  const chain = getChainById(chainId);
  const menuItems = getMenuItemsNesa(chain);

  const [search, setSearch] = useState("");
  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const blocks = useBlocksStore((state) => state.blocksHistory);
  const latestBlock = blocks[0]?.latestBlock?.toString() || "";

  const dataItems: DataItem[] = [
    { data: "$-.-- B", icon: "coins", label: "Market Cap" },
    { data: "$---.-- M", icon: "chartNoAxesColumnIncreasing", label: "24h Volume" },
    { data: getFormattedNumber(latestBlock), icon: "box", label: "Block" },
  ];

  const lg = useMinWidthMediaQuery("lg");
  const sm = useMinWidthMediaQuery("sm");

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!search) {
      toast.error("No search defined");

      return;
    }

    if (txhash.test(search)) {
      navigate(`/${chainId}/transactions/${search}`);
      return;
    }

    if (addr.test(search)) {
      navigate(`/${chainId}/address/${search}`);
      return;
    }

    if (height.test(search)) {
      navigate(`/${chainId}/blocks/${search}`);
      return;
    }

    toast.error("Given value is not a tx hash, account or height");
  };

  return (
    <div className="fixed inset-x-0 top-0 z-20 flex flex-col justify-between gap-2 bg-black pb-2">
      <div className="flex w-full flex-col gap-1 border-b border-clay-900 pb-2 pl-2 sm:h-11 sm:flex-row sm:items-center sm:gap-0 sm:pb-0 sm:pl-8">
        <div className="flex gap-4">
          {linkItems.map((item) => (
            <Link className="flex items-center gap-2" key={item.label} to={item.link}>
              <div className="text-xs text-clay-380 sm:text-sm">{item.label}</div>
              <Icon className="text-white" name="arrowUpRight" />
            </Link>
          ))}
        </div>

        <div className="flex h-full grow items-center justify-between gap-1 pr-2 sm:justify-end sm:gap-6">
          <div className="flex gap-1 sm:gap-6">
            {dataItems.map((item) => (
              <div className="flex items-center gap-2 text-clay-380" key={item.label}>
                <Icon name={item.icon} />
                {sm && <div>{item.label}</div>}
                <div className="rounded-md bg-clay-900 px-2 text-xs text-white sm:text-sm/6">
                  {item.data}
                </div>
              </div>
            ))}
          </div>

          {isConnected ? (
            <Button onClick={() => setIsWalletOpen(true)} variant="filled-light">
              {account && getShortenedAddress(account.bech32Address)}
            </Button>
          ) : (
            <div
              className="flex h-full cursor-pointer items-center gap-2 border-l border-clay-900 px-4 text-xs text-white sm:text-sm"
              onClick={() => {
                setIsProviderOpen(true);
              }}
            >
              <Icon className="text-tusk-100" name="wallet" />
              {sm && <div>Connect Wallet</div>}
            </div>
          )}

          <WalletProviderModal isOpen={isProviderOpen} onOpenChange={setIsProviderOpen} />
          <WalletModal isOpen={isWalletOpen} onOpenChange={setIsWalletOpen} />
        </div>
      </div>

      <div className="flex flex-col justify-between px-2 sm:flex-row sm:px-8">
        <div className="flex flex-col justify-start gap-2 sm:flex-row sm:gap-0">
          <img
            alt="nesa"
            className="ml-2 w-20 cursor-pointer sm:ml-0"
            onClick={() => navigate("/")}
            src={logoSrc}
          />

          <MenuItems items={menuItems} />
        </div>

        <div className="flex items-center justify-center gap-2">
          {lg && (
            <>
              <form className="w-40" onSubmit={handleSearch}>
                <Input
                  className="h-9 w-full"
                  classNameInput="text-white placeholder:text-clay-500"
                  classNameInputWrapper="bg-clay-900 border-clay-900 rounded-lg px-1.5 shadow-sm"
                  endSlot={
                    <div className="flex gap-0.5">
                      <div className="flex size-6 items-center justify-center rounded bg-clay-700 text-xs text-clay-100">
                        ⌘
                      </div>

                      <div className="flex size-6 items-center justify-center rounded bg-clay-700 text-xs text-clay-100">
                        K
                      </div>
                    </div>
                  }
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  size="medium"
                  startSlot={<Icon className="text-clay-100" name="search" />}
                  value={search}
                />
              </form>

              <MenuItems items={menuItemsEcosystem} />
            </>
          )}
          <div />
        </div>
      </div>
    </div>
  );
};

type MenuItemsProps = {
  items: MenuItem[];
};

function MenuItems({ items }: MenuItemsProps) {
  return (
    <div className="ml-2 flex gap-2 sm:ml-8 sm:gap-6 sm:self-center">
      {items.map((item) => (
        <NavLink
          className={({ isActive }) =>
            twMerge(
              "flex cursor-pointer gap-2 text-xs text-clay-100 transition-all hover:text-clay-20 sm:text-sm",
              isActive && "text-tusk-100",
            )
          }
          key={item.link}
          to={item.link}
        >
          {item.icon && <Icon name={item.icon} />}
          {item.label}
        </NavLink>
      ))}
    </div>
  );
}
