import { Link, useParams } from "react-router-dom";

import { DateTime } from "luxon";
import { twJoin } from "tailwind-merge";

import type { Proposal } from "shared/api/proposals";
import type { StakingPoolResponse } from "shared/api/staking/types";
import type { TxResponse } from "shared/api/transactions/types";

import { getChainById } from "shared/helpers/getChainById";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { ProgressLine } from "shared/ui/ProgressLine";

import { statusMap } from "../config";

type Props = {
  poolData?: StakingPoolResponse;
  proposal: Proposal;
};

export const ProposalItem = ({ poolData, proposal }: Props) => {
  const md = useMinWidthMediaQuery("md");

  const { chainId } = useParams<{ chainId: string }>();
  const network = getChainById(chainId || "");

  const percent = calculatePercent(
    proposal.final_tally_result.yes_count,
    poolData?.pool.bonded_tokens,
  );

  const percentElement = (
    <ProgressLine className="md:w-52" classNameLine="bg-primary-800" percent={percent ?? 0} />
  );

  const statusElement = (
    <div className="flex gap-5 md:ml-8  md:flex-col md:gap-0.5">
      <div
        className={twJoin(
          "mb-0.5 text-xs",
          statusMap[proposal.status] === "PASSED" && "text-primary-800",
          statusMap[proposal.status] === "VOTING" && "text-pink-500",
          statusMap[proposal.status] === "REJECTED" && "text-yellow-500",
        )}
      >
        {statusMap[proposal.status] || proposal.status}
      </div>
      <div className="min-w-24 text-right text-xs text-corduroy-400 md:text-left">
        {timeAgo(DateTime.fromISO(proposal.voting_end_time))}
      </div>
    </div>
  );

  const idElement = (
    <div className="text-base font-semibold text-corduroy-900 md:mr-8 md:text-xl">
      #{proposal.id}
    </div>
  );

  const messageElement = (
    <div className="inline-block rounded-md bg-primary-200 px-2 py-0.5 text-xs text-primary-900">
      {getTxMessage(proposal.messages as TxResponse["tx"]["body"]["messages"])}
    </div>
  );

  const titleElement = (
    <div className="flex-1">
      <Link
        className="mb-0.5 block cursor-pointer text-sm text-corduroy-700 duration-200 hover:text-primary-800"
        to={`/${network.chainId}/governance/${proposal.id}`}
      >
        {proposal.title}
      </Link>
      {md && messageElement}
    </div>
  );

  return (
    <div
      className="relative flex flex-col border-t border-corduroy-100 px-3 py-4 first-of-type:border-t-0 md:flex-row md:items-center md:px-6"
      key={proposal.id}
    >
      {md && (
        <>
          {idElement}
          {titleElement}
          {percentElement}
          {statusElement}
        </>
      )}
      {!md && (
        <>
          <div className="flex items-center justify-between gap-3">
            {titleElement}
            {idElement}
          </div>
          <div className="my-2 flex items-center justify-between">
            {messageElement}
            {statusElement}
          </div>
          {percentElement}
        </>
      )}
    </div>
  );
};

function calculatePercent(input?: number | string, total?: number | string) {
  if (!input || !total) return 0;
  const percent = Number(input) / Number(total);

  return Math.ceil((percent > 0.0001 ? percent : 0) * 100);
}
