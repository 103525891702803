import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { fromBase64 } from "@cosmjs/encoding";
import { decodeTxRaw } from "@cosmjs/proto-signing";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { hashTx } from "shared/helpers/decode";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { Table } from "shared/ui/Table";

type Props = {
  transactionsCount?: number;
  txs: { blockHeight?: string; tx: TransactionQueryResponse | string }[];
  withHeader?: boolean;
};

type TransactionColumn = {
  blockHeight?: string;
  hash: string;
  memo: string;
  message: string;
};

export const TableTransactions = ({ transactionsCount, txs, withHeader = true }: Props) => {
  const { chainId } = useParams();

  const chain = getChainById(chainId);

  const transactions = useMemo(() => {
    const transactions = transactionsCount ? txs.slice(0, transactionsCount) : txs;
    return transactions.map(({ blockHeight, tx }): TransactionColumn => {
      const decoded = typeof tx === "string" ? decodeTxRaw(fromBase64(tx)) : null;
      const body = typeof tx !== "string" ? tx.tx.body : null;

      const hash = typeof tx === "string" ? hashTx(fromBase64(tx)) : tx.txhash;

      return {
        blockHeight,
        hash: hash,
        memo: decoded?.body.memo || body?.memo || "-",
        message: getTxMessage(
          (decoded?.body.messages || body?.messages) as TxResponse["tx"]["body"]["messages"],
        ),
      };
    });
  }, [txs, transactionsCount]);

  const transactionsColumns = useMemo(() => {
    const columns: TableColumn<keyof TransactionColumn, TransactionColumn>[] = [
      {
        key: "blockHeight",
        renderTd: ({ blockHeight }) => (
          <Link
            className="cursor-pointer text-sm font-semibold text-primary-900 transition-all duration-200 hover:brightness-90"
            to={`/${chain.chainId}/blocks/${blockHeight}`}
          >
            {getFormattedNumber(blockHeight)}
          </Link>
        ),
        thClassName: "!rounded-none",
        title: withHeader ? "Block" : undefined,
      },
      {
        key: "hash",
        renderTd: (row) => (
          <Link
            className="inline-flex cursor-pointer items-center text-primary-900 transition-all duration-200 hover:brightness-90"
            to={`/${chain.chainId}/transactions/${row.hash}`}
          >
            {row.hash}
          </Link>
        ),
        thClassName: "!rounded-none",
        title: withHeader ? "Hash" : undefined,
      },
      {
        key: "message",
        renderTd: (row) => <div className="max-w-48 truncate">{row.message}</div>,
        thClassName: "!rounded-none",
        title: withHeader ? "Message" : undefined,
      },
      {
        key: "memo",
        renderTd: (row) => row.memo,
        thClassName: "!rounded-none",
        title: withHeader ? "Memo" : undefined,
      },
    ];

    return columns;
  }, [withHeader, chain]);

  return (
    <>
      <div className="w-full overflow-x-auto">
        <Table
          className="min-w-[64rem]"
          columns={transactionsColumns}
          data={transactions || []}
          hovered
        />
      </div>
    </>
  );
};
