import type { InputHTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";

import { twMerge } from "tailwind-merge";

import type { ClassName } from "shared/types";

export type InputProps = {
  classNameInput?: string;
  classNameInputWrapper?: string;
  endSlot?: ReactNode;
  error?: string;
  errorSpacing?: boolean;
  size?: "medium" | "small";
  startSlot?: ReactNode;
} & ClassName &
  Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      classNameInput,
      classNameInputWrapper,
      disabled,
      endSlot: EndSlot,
      error,
      errorSpacing,
      size = "small",
      startSlot: StartSlot,
      ...inputProps
    }: InputProps,
    ref,
  ) => {
    return (
      <div className={twMerge("relative flex w-full flex-col", errorSpacing && "pb-4", className)}>
        <div
          className={twMerge(
            "flex w-full max-w-full items-center rounded-lg bg-white px-3 transition-colors",
            "border border-corduroy-200 outline outline-1 outline-offset-0 outline-transparent focus-within:border-primary-900 hover:border-primary-1000",
            !!error && "border-pink-500 focus-within:border-pink-500 hover:border-pink-500",
            !!StartSlot && "pl-2",
            !!EndSlot && "pr-2",
            disabled && "cursor-auto bg-corduroy-200",
            size === "small" && "h-10 text-xs 3xl:text-sm",
            size === "medium" && "h-[2.875rem] text-sm",
            classNameInputWrapper,
          )}
        >
          {StartSlot && <div className="mr-2 inline-flex select-none">{StartSlot}</div>}

          <input
            {...inputProps}
            className={twMerge(
              "h-full w-inherit bg-white/0 text-corduroy-900 focus:outline-none",
              classNameInput,
            )}
            disabled={disabled}
            ref={ref}
          />

          {EndSlot && <div className="ml-2 inline-flex min-w-max select-none">{EndSlot}</div>}
        </div>
        {error && (
          <div className="absolute bottom-3 translate-y-full text-[10px] leading-none text-pink-500">
            {error}
          </div>
        )}
      </div>
    );
  },
);
