import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import type { TxResponse } from "shared/api/transactions/types";
import type { TableColumn } from "shared/ui/Table";

import { getTxMessage } from "shared/helpers/getTxMessage";
import { Icon } from "shared/ui/Icon";

export const useTxsColumns = () => {
  const navigate = useNavigate();
  const { chainId } = useParams();

  return [
    {
      key: "height",
      renderTd: ({ height }) => {
        return <div>{height}</div>;
      },
      title: "Block Height",
    },
    {
      key: "txhash",
      renderTd: ({ txhash }) => {
        return (
          <div
            className="cursor-pointer text-primary-900 transition-colors hover:text-primary-1000"
            onClick={() => navigate(`/${chainId}/transactions/${txhash}`)}
          >
            {txhash.slice(0, 10) + "..." + txhash.slice(-6)}
          </div>
        );
      },
      title: "Tx Hash",
    },
    {
      key: "tx",
      renderTd: ({ code, tx }) => {
        return (
          <div className="flex min-w-fit items-center gap-1">
            {getTxMessage(tx.body.messages)}{" "}
            {code !== undefined && (
              <Icon
                className={twMerge("text-pink-500", code === 0 && "text-primary-900")}
                name={code === 0 ? "check" : "close"}
              />
            )}
          </div>
        );
      },
      title: "Messages",
    },
    {
      key: "timestamp",
      renderTd: ({ timestamp }) => {
        return (
          <div className="whitespace-nowrap">
            {timestamp ? DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd hh:mm:ss") : "-"}
          </div>
        );
      },
      title: "Timestamp",
    },
  ] as TableColumn<keyof TxResponse, TxResponse>[];
};
