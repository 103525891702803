import { Ripemd160, sha256 } from "@cosmjs/crypto";
import { fromBase64, toHex } from "@cosmjs/encoding";

export function consensusPubkeyToHexAddress(consensusPubkey?: { "@type": string; key: string }) {
  if (!consensusPubkey) return "";
  const raw = "";
  if (consensusPubkey["@type"] === "/cosmos.crypto.ed25519.PubKey") {
    const pubkey = fromBase64(consensusPubkey.key);
    if (pubkey) return toHex(sha256(pubkey)).slice(0, 40).toUpperCase();
  }

  if (consensusPubkey["@type"] === "/cosmos.crypto.secp256k1.PubKey") {
    const pubkey = fromBase64(consensusPubkey.key);
    if (pubkey) return toHex(new Ripemd160().update(sha256(pubkey)).digest());
  }
  return raw;
}

export const convertHash = (hash: string | undefined) => {
  if (hash && hash.endsWith("=") && hash.length !== 28) {
    return toHex(fromBase64(hash)).toUpperCase();
  }

  return "";
};

export const hashTx = (raw: Uint8Array) => {
  return toHex(sha256(raw)).toUpperCase();
};
