import { useQuery } from "@tanstack/react-query";

import type { UseQueryOptions } from "shared/types";

import { axiosNodeInstance } from "shared/utils/axiosInstance";

import { transactionKeys } from "./queryKeys";

export type TotalTransactionQueryResponse = {
  date: string;
  total: number;
};

type Params = { endDate: string; startDate: string };

export const useTotalTransactionByPeriodQuery = (
  params: Params,
  options: UseQueryOptions<TotalTransactionQueryResponse[]> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosNodeInstance.get<TotalTransactionQueryResponse[]>(
        "/transactions/total-by-period",
        {
          params,
          signal,
        },
      );
      return data;
    },
    queryKey: transactionKeys.totalTransactionsByPeriod(params),
    ...options,
  });
};
