import type { ComponentPropsWithoutRef } from "react";

import { twMerge } from "tailwind-merge";

import type { TableColumn, TableRowExt } from "../types";

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  column: TableColumn<ColumnKey, Row>;
  hasTh?: boolean;
  hovered?: boolean;
  isClicked?: boolean;
  row: Row;
};

export const Td = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  column,
  hasTh,
  hovered,
  isClicked,
  row,
}: Props<ColumnKey, Row>) => {
  return (
    <TdUI
      className={twMerge(
        (isClicked || hovered) && "transition-colors group-hover:bg-clay-1100/50",
        !hasTh && "",
        column.tdClassName,
      )}
    >
      {column.renderTd(row)}
    </TdUI>
  );
};

export const TdUI = (props: ComponentPropsWithoutRef<"td">) => {
  return (
    <td
      {...props}
      className={twMerge(
        "bg-clay-1100 px-4 py-3.5 text-sm font-normal text-clay-500 first:rounded-l-lg last:rounded-r-lg",
        props.className,
      )}
    />
  );
};
